import { Observable } from 'rxjs';
import { FeatureNames } from '../../model/FeatureNames';

export interface TabConfig {
    title: string;
    icon?: string;
    path?: PathExpr;
    condition?: ConditionExpr;
    options?: {
        /**
         * If true, the tab will be shown even if no subtabs render.
         */
        showIfEmpty?: boolean;
    };

    items?: SubTabConfig[];
}

export interface SubTabConfig {
    title: string;
    path?: PathExpr;
    condition?: ConditionExpr;
    options?: {};
}

export type PathExpr = string | { type: 'url'; href: string };

export type ConditionExpr =
    | { check: 'every' | 'some'; conditions: ConditionExpr[] }
    | { check: 'isAllowed'; permission: string }
    | { check: 'featureEnabled'; featureName: FeatureNames }
    | CustomPredicate;

export const ExternalUrl = (href: string) => ({ type: 'url', href }) as const;

export type CustomPredicate = () => Observable<boolean>;

export const IsAllowed = (permission: string) =>
    ({
        check: 'isAllowed',
        permission,
    }) as const;

export const IsFeatureEnabled = (featureName: FeatureNames) =>
    ({
        check: 'featureEnabled',
        featureName,
    }) as const;

export const AllOf = (...conditions: ConditionExpr[]) =>
    ({
        check: 'every',
        conditions,
    }) as const;

export const SomeOf = (...conditions: ConditionExpr[]) =>
    ({
        check: 'some',
        conditions,
    }) as const;
