import { Injectable } from '@angular/core';
import { FeatureFlagStatus } from '@pure/pure1-ui-platform-angular';
import {
    AutocompleteKeyParams,
    AutocompleteKeyResponse,
    AutocompleteService,
    AutocompleteValueParams,
    AutocompleteValueResponse,
    FeatureFlagDxpService,
    ProgramType,
    Resource,
    View,
} from '@pure1/data';
import { forkJoin, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { FeatureNames } from '../model/FeatureNames';
import { ARRAY_NAME_KEY, CITY_KEY, COUNTRY_KEY, FQDN, MODEL_KEY, VERSION_KEY } from '../redux/actions';

export type GfbFilterMode = 'views' | 'metric_groups' | 'default';

const featureNameFieldMap: Map<FeatureNames, string[]> = new Map<FeatureNames, string[]>([
    [FeatureNames.HW_EOL, ['has_end_of_life_hardware', 'end_of_life_hardware']],
    [FeatureNames.CAPACITY_DOWN_LICENSING_APPLIANCE, ['capacity_expandable']],
]);

@Injectable() // Not provided in root: only gets used as sandboxed instance
export class GfbAutocompleteService {
    private filterForSingleValueTags: GfbFilterMode = 'default';
    private viewsCache: Map<string, View>; // set per instance
    private orgId: number = null;
    private readonly filterModeKeys: Map<GfbFilterMode, Set<string>> = new Map([
        ['views', new Set([ARRAY_NAME_KEY, CITY_KEY, COUNTRY_KEY, MODEL_KEY, 'os', VERSION_KEY])],
        ['metric_groups', new Set([ARRAY_NAME_KEY, CITY_KEY, COUNTRY_KEY, FQDN, MODEL_KEY, 'os', VERSION_KEY])],
    ]);
    private programType: ProgramType = null;
    private enabledPolicyFields: Map<string, boolean> = new Map<string, boolean>();

    constructor(
        private autocomplete: AutocompleteService,
        private featureFlagDxpService: FeatureFlagDxpService,
    ) {
        forkJoin({
            [FeatureNames.HW_EOL]: featureFlagDxpService.getFeatureFlag(FeatureNames.HW_EOL),
            [FeatureNames.CAPACITY_DOWN_LICENSING_APPLIANCE]: featureFlagDxpService.getFeatureFlag(
                FeatureNames.CAPACITY_DOWN_LICENSING_APPLIANCE,
            ),
        })
            .pipe(take(1))
            .subscribe(response => {
                const features: Map<FeatureNames, FeatureFlagStatus> = new Map<FeatureNames, FeatureFlagStatus>(
                    Object.entries(response) as [FeatureNames, FeatureFlagStatus][],
                );
                for (const featureName of features.keys()) {
                    for (const field of featureNameFieldMap.get(featureName)) {
                        this.enabledPolicyFields.set(field, features.get(featureName)?.enabled === true);
                    }
                }
            });
    }

    getKeys(params$: Observable<AutocompleteKeyParams>): Observable<AutocompleteKeyResponse> {
        // for all other entities, pass through to autocomplete service
        return this.autocomplete.getKeys(this.setGfbFilter(params$)).pipe(
            map((keyResponse: AutocompleteKeyResponse) => {
                let { keys } = keyResponse;
                const allowedKeys = this.filterModeKeys.get(this.filterForSingleValueTags);
                if (allowedKeys && keyResponse.entity === 'arrays') {
                    keys = keys.filter(key => key.namespace !== null || allowedKeys.has(key.key));
                }
                keys = keys.filter(tagSummary =>
                    this.enabledPolicyFields.has(tagSummary.key) ? this.enabledPolicyFields.get(tagSummary.key) : true,
                );
                return { ...keyResponse, keys };
            }),
        );
    }

    getValues(params$: Observable<AutocompleteValueParams>): Observable<AutocompleteValueResponse[]> {
        return this.autocomplete.getValues(this.setGfbFilter(params$));
    }

    getView(viewReference: Resource): View {
        return this.viewsCache.get(viewReference.id);
    }

    enableViewFiltering(orgId?: number, programType?: ProgramType): void {
        this.setOrgScope(orgId);
        this.setTagFilter('views');
        this.setProgramTypeFilter(programType);
    }

    enableMetricGroups(orgId: number): void {
        this.setOrgScope(orgId);
        this.setTagFilter('metric_groups');
    }

    setOrgScope(orgId?: number): void {
        this.orgId = orgId ?? null;
    }

    setProgramTypeFilter(programType?: ProgramType): void {
        this.programType = programType ?? null;
    }

    setTagFilter(val: GfbFilterMode): void {
        this.filterForSingleValueTags = val;
    }

    private setGfbFilter<T = AutocompleteKeyParams | AutocompleteValueParams>(params$: Observable<T>): Observable<T> {
        const orgValue = this.orgId || null;
        const programTypeValue = this.programType || null;
        if (this.orgId || this.programType) {
            return params$.pipe(
                map(params => Object.assign(params, { orgId: orgValue, programType: programTypeValue })),
            );
        }
        return params$;
    }
}
