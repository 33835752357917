import { Injectable } from '@angular/core';
import { DraasApiConfig } from './disaster-recovery-constants';
import { map, Observable } from 'rxjs';
import {
    DraasApiAWSProvider,
    DraasApiBlobData,
    DraasApiCloudFormationTemplate,
    DraasApiCluster,
    DraasApiConfigureVSphereStorage,
    DraasApiCreateDeployment,
    DraasApiCreateAWSProviderV2,
    DraasApiCreateVSphereProviderV2,
    DraasApiCredentialsChangeRequest,
    DraasApiVSphereCredentials,
    DraasApiVSphereNetwork,
    DraasApiVSphereProvider,
    DraasApiUpdateAWSProviderNetwork,
    DraasApiOvaUrl,
} from '@pure/paas-api-gateway-client-ts';
import { DisasterRecoveryCluster } from '../models/disaster-recovery-cluster';
import { DisasterRecoveryAwsProvider, DisasterRecoveryVSphereProvider } from '../models/disaster-recovery-provider';
import { DisasterRecoveryCredentialsChangeRequest } from '../models/disaster-recovery-credentials-change-request';
import { DisasterRecoveryThrottlingHttpClient } from './disaster-recovery-throttling-http-client.service';

@Injectable({ providedIn: 'root' })
export class DisasterRecoveryDeploymentsService {
    constructor(protected http: DisasterRecoveryThrottlingHttpClient) {}

    private getEndpoint(): string {
        return `${DraasApiConfig.getUrlPrefix()}/api/2.0/deployments`;
    }

    createDeployment(name: string, licenseKey: string, description: string): Observable<DisasterRecoveryCluster> {
        const url = this.getEndpoint();
        const body: DraasApiCreateDeployment = {
            name,
            license_key: licenseKey,
            description,
        };
        return this.http.post<DraasApiCluster>(url, body).pipe(map(cluster => new DisasterRecoveryCluster(cluster)));
    }

    createVSphereSite(clusterId: string, name: string): Observable<DisasterRecoveryVSphereProvider> {
        const url = `${this.getEndpoint()}/${clusterId}/vsphere`;
        const body: DraasApiCreateVSphereProviderV2 = {
            name,
        };
        return this.http
            .post<DraasApiVSphereProvider>(url, body)
            .pipe(map(provider => new DisasterRecoveryVSphereProvider(provider)));
    }

    createAwsSite(clusterId: string, name: string): Observable<DisasterRecoveryAwsProvider> {
        const url = `${this.getEndpoint()}/${clusterId}/aws`;
        const body: DraasApiCreateAWSProviderV2 = {
            name,
        };
        return this.http
            .post<DraasApiAWSProvider>(url, body)
            .pipe(map(provider => new DisasterRecoveryAwsProvider(provider)));
    }

    configureVSphereWorkerVmNetwork(
        clusterId: string,
        providerId: string,
        body: DraasApiVSphereNetwork,
    ): Observable<DisasterRecoveryVSphereProvider> {
        const url = `${this.getEndpoint()}/${clusterId}/vsphere/${providerId}/config/worker-vm-network`;
        return this.http
            .post<DraasApiVSphereProvider>(url, body)
            .pipe(map(provider => new DisasterRecoveryVSphereProvider(provider)));
    }

    getVSphereBlob(clusterId: string, providerId: string): Observable<string> {
        const url = `${this.getEndpoint()}/${clusterId}/vsphere/${providerId}/blob`;
        return this.http.get<DraasApiBlobData>(url).pipe(map(blobData => blobData.blob));
    }

    getOvaUrl(clusterId: string): Observable<string> {
        const url = `${this.getEndpoint()}/${clusterId}/ova-url`;
        return this.http.post<DraasApiOvaUrl>(url, null).pipe(map(blobData => blobData.url));
    }

    saveVSphereCredentials(
        clusterId: string,
        providerId: string,
        credentials: DraasApiVSphereCredentials,
    ): Observable<DisasterRecoveryCredentialsChangeRequest> {
        const url = `${this.getEndpoint()}/${clusterId}/vsphere/${providerId}/config/credentials`;
        return this.http
            .post<DraasApiCredentialsChangeRequest>(url, credentials)
            .pipe(map(changeRequest => new DisasterRecoveryCredentialsChangeRequest(changeRequest)));
    }

    configureVSphereStorage(
        clusterId: string,
        providerId: string,
        storage: DraasApiConfigureVSphereStorage,
    ): Observable<DisasterRecoveryVSphereProvider> {
        const url = `${this.getEndpoint()}/${clusterId}/vsphere/${providerId}/config/storage`;
        return this.http
            .post<DraasApiVSphereProvider>(url, storage)
            .pipe(map(provider => new DisasterRecoveryVSphereProvider(provider)));
    }

    configureAwsNetwork(
        clusterId: string,
        providerId: string,
        network: DraasApiUpdateAWSProviderNetwork,
    ): Observable<DisasterRecoveryAwsProvider> {
        const url = `${this.getEndpoint()}/${clusterId}/aws/${providerId}/config/network`;
        return this.http
            .post<DraasApiAWSProvider>(url, network)
            .pipe(map(provider => new DisasterRecoveryAwsProvider(provider)));
    }

    generateCloudFormation(clusterId: string, providerId: string): Observable<string> {
        const url = `${this.getEndpoint()}/${clusterId}/aws/${providerId}/generate-cloud-formation`;
        return this.http
            .post<DraasApiCloudFormationTemplate>(url, {})
            .pipe(map(cloudFormationTemplate => cloudFormationTemplate.s3PresignedUrl));
    }
}
