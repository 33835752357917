import moment from 'moment-timezone';

export enum UpgradeCheckStatus {
    FINALIZED = 'FINALIZED',
    NEW = 'NEW',
    QUEUED = 'QUEUED',
    COMPLETE = 'COMPLETE',
    UNKNOWN = 'UNKNOWN',
    REGISTERED = 'REGISTERED',
    REQUESTED = 'REQUESTED',
    FAILED_TO_START = 'FAILED_TO_START',
}

export enum PrecheckType {
    CLOUD = 'Cloud',
    ON_ARRAY = 'On Array',
}

export interface Precheck {
    succeeded: boolean;
    arrayId: string;
    id: number;
    lastUpdatedBy: string;
    lastUpdateTime: moment.Moment;
    createdBy: string;
    createTime: moment.Moment;
    checkTime: null | moment.Moment;
    groupId: string;
    status: UpgradeCheckStatus;
    type?: PrecheckType;
    checks?: Check[];
    startVersion: string;
    targetVersion: string;
    uiName: CloudPrecheckUiName;
    upgradePath: string[];
}

export interface CloudPrecheck extends Precheck {
    header: string;
    overridden: boolean;
    softwareName: string;
    userMessage: string;
    checks?: CloudCheck[];
    type: PrecheckType.CLOUD;
}

export interface OnArrayPrecheckJob extends Precheck {
    arrayHost: string;
    emsId: string;
    checks?: OnArrayPrecheckJobCheck[];
    finalized: boolean;
    hardwareModel: string;
    header: string;
    lastUpdatedBy: string;
    lastUpdateTime: moment.Moment;
    orgId: number;
    orgName: string;
    category?: 'mid-upgrade' | 'post-upgrade' | 'pre-upgrade';
    overridden: boolean;
    primaryControllerName: string;
    salesforceId: string;
    sessionId: string;
    softwareNames: string[];
    softwareVersions: string[];
    statusCode: string;
    statusReason: string;
    type: PrecheckType.ON_ARRAY;
}

export enum CheckStatus {
    PASSED = 'PASSED',
    FAILED = 'FAILED',
    OVERRIDDEN = 'OVERRIDDEN',
}

export interface Check {
    passed: boolean;
    status: CheckStatus;
    overridable: boolean;
    blockSsuDownload: boolean;
    name: string;
    details: string;
    id: number;
    customerAddressable: boolean;
}

export interface CloudCheck extends Check {
    description: string;
    detailRecordComments: string[];
    detailRecords: { [key: string]: string }[];
}

export interface OnArrayPrecheckJobCheck extends Check {
    overridden: boolean;
    overridableComment: string;
}

export const isCloudPrecheck = (precheck: Precheck): precheck is CloudPrecheck => {
    return precheck.type === PrecheckType.CLOUD;
};

export const isOnArrayPrecheck = (precheck: Precheck): precheck is OnArrayPrecheckJob => {
    return precheck.type === PrecheckType.ON_ARRAY;
};

export interface ICloudPrechecksExportRequestPayload extends IEmailExportRequestPayload {
    precheck: Omit<CloudPrecheck, 'lastUpdateTime' | 'createTime' | 'checkTime'> & {
        lastUpdateTime: number;
        createTime: number;
        checkTime: number;
    };
    array: ICloudPrechecksPayloadArray;
}

export interface ICloudPrechecksPayloadArray {
    arrayId: string;
    arrayName: string;
    arrayModel: string;
    sourceVersion: string;
}

export interface UpgradePrecheckCheckDefinition {
    abbreviation: string;
    createTime: Date | moment.Moment;
    description: string;
    descriptionInternal: string;
    id: number;
    introduceTime: Date | moment.Moment;
    kbUrl: string;
    kbUrlInternal: string;
    lastUpdateTime: Date | moment.Moment;
    name: string;
    sourceUrlInternal: string;
    displayName: string;
}

export enum LifecycleState {
    /**
     * Directed Availability, Available to a preselected set of customers
     */
    DA = 'DA',
    /**
     * End of Life, The software reached its end of life
     */
    EOL = 'EOL',
    /**
     * Enterprise-ready, General Availability release and meets field deployment quality metrics for Enterprise Readiness
     */
    ER = 'ER',
    /**
     * General Availability, Generally available to customers
     */
    GA = 'GA',
    /**
     * Proof of Concept, Initial proof of concept available
     */
    POC = 'POC',
    /**
     * Planned
     */
    Planned = 'Planned',
    /**
     * Unspecified
     */
    Unspecified = 'Unspecified',
}

export enum LifecycleStateText {
    DA = 'Directed',
    EOL = 'End of Life',
    ER = 'Enterprise',
    GA = 'General',
    POC = 'Proof of Concept',
    Planned = 'Planned',
    Unspecified = 'Unspecified',
}

export enum SoftwareType {
    PurityFA = 'PurityFA',
    PurityFB = 'PurityFB',
    PurityFAAzure = 'PurityFAAzure',
    PurityFAAws = 'PurityFAAws',
}

export enum SupportState {
    EndOfLife = 'EndOfLife',
    Experimental = 'Experimental',
    LongLife = 'LongLife',
    Preliminary = 'Preliminary',
    Standard = 'Standard',
    Unspecified = 'Unspecified',
    Unsupported = 'Unsupported',
    Untested = 'Untested',
}

export enum SupportStateText {
    EndOfLife = 'End of Life',
    Experimental = 'Experimental',
    LongLife = 'Long Life Release',
    Preliminary = 'Preliminary',
    Standard = 'Standard Release',
    Unspecified = 'Unspecified',
    Unsupported = 'Unsupported',
    Untested = 'Untested',
}

export enum SupportStateShortcut {
    EndOfLife = 'EOL',
    Experimental = 'XP',
    LongLife = 'LLR',
    Preliminary = 'PRE',
    Standard = 'SR',
    Unspecified = 'UNSPEC',
    Unsupported = 'UNSUP',
    Untested = 'UT',
}

export enum Visibility {
    Internal = 'Internal',
    Public = 'Public',
    Restricted = 'Restricted',
}

export enum Recommendation {
    Green = 'Green',
    Red = 'Red',
    Yellow = 'Yellow',
}

export interface Release {
    createTime: moment.Moment;
    daTime: moment.Moment;
    eolTime: moment.Moment;
    emsSupported: boolean;
    gaTime: moment.Moment;
    id: number;
    issues: [];
    lastUpdateTime: moment.Moment;
    lifecycleState: LifecycleState;
    recommendation: Recommendation;
    releaseNotesUrl: string;
    releaseTime: moment.Moment;
    softwareType: SoftwareType;
    supportState: SupportState;
    versionFull: string;
    versionMajor: number;
    versionMinor: number;
    versionPatch: number;
    versionSuffix: string;
    visibility: Visibility;
}

export interface Sortable {
    empty: boolean;
    sorted: boolean;
    unsorted: boolean;
}

export interface Pageable {
    sort: Sortable;
    offset: number;
    pageNumber: number;
    pageSize: number;
    paged: boolean;
    unpaged: boolean;
}

export interface PagedResult<T> {
    content: T[];
    empty: boolean;
    first: boolean;
    last: boolean;
    /**
     * Page number
     */
    number: number;
    /**
     * Number of returned elements
     */
    numberOfElements: number;
    /**
     * Page size
     */
    size: number;
    totalElements: number;
    totalPages: number;
    continutionToken: string;
    pageable: Pageable;
    sort: Sortable;
}

export enum WorkflowCreateRequestType {
    CDU = 'CDU',
    PDU = 'PDU',
    PATCH = 'PATCH',
    PRECHECK = 'PRECHECK',
}

export enum DownloadStatus {
    DOWNLOADED = 'DOWNLOADED',
    DOWNLOADING = 'DOWNLOADING',
    REGISTERED = 'REGISTERED',
    TRIGGERED = 'TRIGGERED',
    FAILED = 'FAILED',
    PENDING = 'PENDING',
    PARTIALLY_INSTALLED = 'PARTIALLY_INSTALLED',
    DOWNLOAD_PENDING_PRECHECKS = 'DOWNLOAD_PENDING_PRECHECKS',
    ABORTED = 'ABORTED',
}

export interface WorkflowCreateRequest {
    type: WorkflowCreateRequestType;
}

export interface UpgradeWorkflowCreateRequest extends WorkflowCreateRequest {
    arrayId: string;
    startVersion: string;
    targetVersion: string;
    safeModeAutoOn: boolean;
}

export interface Duration {
    nano: number;
    negative: boolean;
    seconds: number;
    units: TemporalUnit;
    zero: boolean;
}

export interface TemporalUnit {
    dateBased: boolean;
    duration: Duration;
    durationEstimated: boolean;
    timeBased: boolean;
}

export enum WorkflowInstanceModel {
    DownloadUpgrade = 'DownloadUpgrade',
    InstallUpgrade = 'InstallUpgrade',
    Upgrade = 'Upgrade',
    Patch = 'Patch',
}

export enum WorkflowInstanceStatus {
    ACTIVE = 'ACTIVE',
    FINISHED = 'FINISHED',
}

export enum UpgradeWorkflowInstanceStatus {
    DOWNLOADED = 'DOWNLOADED',
    DOWNLOADING = 'DOWNLOADING',
    DOWNLOAD_COMPLETE = 'DOWNLOAD_COMPLETE',
    DOWNLOAD_ABORTED = 'DOWNLOAD_ABORTED',
    DOWNLOAD_FAILED = 'DOWNLOAD_FAILED',
    FAILED = 'FAILED',
    INSTALLING = 'INSTALLING',
    INSTALLATION_ABORTED = 'INSTALLATION_ABORTED',
    INSTALLATION_FAILED = 'INSTALLATION_FAILED',
    INSTALLED = 'INSTALLED',
    PENDING = 'PENDING',
    UNKNOWN = 'UNKNOWN',
    UNTRACKED = 'UNTRACKED',
}

interface WorkflowInstance {
    children: WorkflowInstance[];
    createTime: moment.Moment;
    createdBy: string;
    customerScreenId: string;
    duration: Duration;
    endTime: moment.Moment;
    id: number;
    internalScreenId: string;
    lastUpdateTime: moment.Moment;
    machineState: string;
    model: WorkflowInstanceModel;
    name: WorkflowCreateRequestType;
    parentId: number;
    rootLevel: boolean;
    startTime: moment.Moment;
    status: WorkflowInstanceStatus;
    supportCaseId: string;
    targetTime: moment.Moment;
    logs: WorkflowInstanceLog[];
    currentVersion: string;
    targetVersion: string;
    arrayHost: string;
    arrayId?: string;
    autoCreatedSupportCaseId?: string;
}

export interface WorkflowInstanceLog {
    createTime: moment.Moment;
    createdBy: string;
    lastUpdateTime: moment.Moment;
    id: number;
    message: string;
    severity: WorkflowInstanceLogSeverity;
    source: string;
    sourceLine: number;
    workflowInstanceId: number;
}

export enum WorkflowInstanceLogSeverity {
    TRACE = 'TRACE',
    DEBUG = 'DEBUG',
    INFO = 'INFO',
    WARN = 'WARN',
    ERROR = 'ERROR',
    FATAL = 'FATAL',
}

export enum PatchStatus {
    CREATED = 'CREATED',
    PENDING = 'PENDING',
    ABORTED = 'ABORTED',
    INSTALLING = 'INSTALLING',
    INSTALLED = 'INSTALLED',
    FAILED = 'FAILED',
    UNKNOWN = 'UNKNOWN',
}

export const PatchStatusText: { [key in PatchStatus]: string } = {
    CREATED: 'Created',
    PENDING: 'Pending',
    ABORTED: 'Aborted',
    INSTALLING: 'Installing',
    INSTALLED: 'Installed',
    FAILED: 'Failed',
    UNKNOWN: 'Unknown',
};

export interface PatchWorkflowInstance extends WorkflowInstance {
    arrayId: string;
    patchName: string;
    patchStatus: PatchStatus;
    details: string;
    internalDetails: string;
}

export interface UpgradeWorkflowInstance extends WorkflowInstance {
    arrayId: string;
    currentVersion: string;
    startVersion: string;
    targetVersion: string;
    upgradePath: string[];
    upgradeScriptVersion: string;
    upgradeStatus: UpgradeWorkflowInstanceStatus;
    synchronizedUpgrade?: SynchronizedUpgrade;
    cloudPrecheckId?: number;
    onArrayPrecheckId?: number;
    precheckStatus?: UpgradeCheckStatus;
}

export interface SynchronizedUpgrade {
    status: string;
    targetVersion: string;
    upgradeWorkflowIds: number[];
}

export enum InstallStatus {
    PENDING = 'PENDING',
    STARTING = 'STARTING',
    STARTED = 'STARTED',

    PRE_CHECKING = 'PRE_CHECKING',
    PRE_CHECKS_FINISHED = 'PRE_CHECKS_FINISHED',
    PAUSING_AFTER_PRE_CHECKS = 'PAUSING_AFTER_PRE_CHECKS',
    PAUSED_AFTER_PRE_CHECKS = 'PAUSED_AFTER_PRE_CHECKS',
    PAUSING_AFTER_POST_CHECKS = 'PAUSING_AFTER_POST_CHECKS',
    PAUSED_AFTER_POST_CHECKS = 'PAUSED_AFTER_POST_CHECKS',

    SECONDARY_INSTALLING = 'SECONDARY_INSTALLING',
    SECONDARY_INSTALLING_AFTER_REBOOT = 'SECONDARY_INSTALLING_AFTER_REBOOT',
    SECONDARY_INSTALLED = 'SECONDARY_INSTALLED',

    MID_CHECKING = 'MID_CHECKING',
    MID_CHECKS_FINISHED = 'MID_CHECKS_FINISHED',
    PAUSING_AFTER_MID_CHECKS = 'PAUSING_AFTER_MID_CHECKS',
    PAUSED_AFTER_MID_CHECKS = 'PAUSED_AFTER_MID_CHECKS',

    FAILOVER_PENDING = 'FAILOVER_PENDING',
    FAILOVER_IN_PROGRESS = 'FAILOVER_IN_PROGRESS',

    NEW_SECONDARY_PRE_CHECKING = 'NEW_SECONDARY_PRE_CHECKING',
    NEW_SECONDARY_PRE_CHECKS_FINISHED = 'NEW_SECONDARY_PRE_CHECKS_FINISHED',

    NEW_SECONDARY_INSTALLING = 'NEW_SECONDARY_INSTALLING',
    NEW_SECONDARY_INSTALLING_AFTER_REBOOT = 'NEW_SECONDARY_INSTALLING_AFTER_REBOOT',
    NEW_SECONDARY_INSTALLED = 'NEW_SECONDARY_INSTALLED',

    POST_CHECKING = 'POST_CHECKING',
    POST_CHECKS_FINISHED = 'POST_CHECKS_FINISHED',

    UPGRADING_DFS = 'UPGRADING_DFS',
    UPGRADED_DFS = 'UPGRADED_DFS',

    UPGRADING_OFFLOAD_APP = 'UPGRADING_OFFLOAD_APP',
    UPGRADED_OFFLOAD_APP = 'UPGRADED_OFFLOAD_APP',

    FINISHING = 'FINISHING',
    FINISHED = 'FINISHED',

    // check failures: currently unused (error path needs further testing)
    PRE_CHECKS_FAILED = 'PRE_CHECKS_FAILED',
    MID_CHECKS_FAILED = 'MID_CHECKS_FAILED',
    NEW_SECONDARY_PRE_CHECKS_FAILED = 'NEW_SECONDARY_PRE_CHECKS_FAILED',
    POST_CHECKS_FAILED = 'POST_CHECKS_FAILED',

    // installation failures: currently unused (error path needs further testing)
    SECONDARY_INSTALLATION_FAILED = 'SECONDARY_INSTALLATION_FAILED',
    NEW_SECONDARY_INSTALLATION_FAILED = 'NEW_SECONDARY_INSTALLATION_FAILED',

    // alternate path
    ABORTING = 'ABORTING',
    ABORTING_REVERTING_SECONDARY = 'ABORTING_REVERTING_SECONDARY',
    ABORTING_REVERTED_SECONDARY = 'ABORTING_REVERTED_SECONDARY',
    ABORTING_DUE_TO_ERROR = 'ABORTING_DUE_TO_ERROR',
    ABORTED_DUE_TO_ERROR = 'ABORTED_DUE_TO_ERROR',
    ABORTING_REBOOTING_ORIGINAL_SECONDARY = 'ABORTING_REBOOTING_ORIGINAL_SECONDARY',
    ABORTING_REBOOTED_ORIGINAL_SECONDARY = 'ABORTING_REBOOTED_ORIGINAL_SECONDARY',
    ABORTED = 'ABORTED',

    // other statuses
    UNDEFINED = 'UNDEFINED',
    UNKNOWN = 'UNKNOWN',
}

export const InstallStatusText: { [key in InstallStatus]: { name: string; description: string } } = {
    PENDING: {
        name: 'Pending',
        description: 'Installation is initializing',
    },
    STARTING: {
        name: 'Starting',
        description: 'Installation is preparing',
    },
    STARTED: {
        name: 'Started',
        description: 'Installation has started',
    },

    PRE_CHECKING: {
        name: 'Prechecking',
        description: 'Upgrade prechecks are in progress',
    },
    PRE_CHECKS_FINISHED: {
        name: 'Prechecks finished',
        description: 'Finalizing prechecks',
    },
    PAUSING_AFTER_PRE_CHECKS: {
        name: 'Pausing after prechecks',
        description: 'Upgrade prechecks are complete',
    },
    PAUSED_AFTER_PRE_CHECKS: {
        name: 'Paused after prechecks',
        description: 'Installation paused after prechecks, waiting for user interaction',
    },
    PAUSING_AFTER_POST_CHECKS: {
        name: 'Pausing after postchecks',
        description: 'Upgrade postchecks are complete',
    },
    PAUSED_AFTER_POST_CHECKS: {
        name: 'Paused after postchecks',
        description: 'Installation paused after postchecks',
    },

    SECONDARY_INSTALLING: {
        name: 'Secondary installing',
        description: 'Secondary controller installation is in progress',
    },
    SECONDARY_INSTALLING_AFTER_REBOOT: {
        name: 'Secondary installing after reboot',
        description: 'Following a reboot, secondary controller installation is in progress',
    },
    SECONDARY_INSTALLED: {
        name: 'Secondary installed',
        description: 'Secondary controller installation is complete',
    },

    MID_CHECKING: {
        name: 'Mid-checking',
        description: 'Midpoint upgrade checks are in progress',
    },
    MID_CHECKS_FINISHED: {
        name: 'Mid-upgrade checks finished',
        description: 'Finalizing midpoint upgrade checks',
    },
    PAUSING_AFTER_MID_CHECKS: {
        name: 'Pausing after mid-upgrade checks',
        description: 'Midpoint upgrade checks are complete',
    },
    PAUSED_AFTER_MID_CHECKS: {
        name: 'Paused after mid-upgrade checks',
        description: 'Switch to primary controller',
    },

    FAILOVER_PENDING: {
        name: 'Failover to new primary pending',
        description: 'Failover to new primary controller initializing',
    },
    FAILOVER_IN_PROGRESS: {
        name: 'Failing over to new primary',
        description: 'Failover to new primary controller is in progress',
    },

    NEW_SECONDARY_PRE_CHECKING: {
        name: 'Prechecking new secondary',
        description: 'Installation  prechecks on the new secondary controller are in progress',
    },
    NEW_SECONDARY_PRE_CHECKS_FINISHED: {
        name: 'Prechecked new secondary',
        description: 'Installation prechecks on the new secondary controller are complete',
    },

    NEW_SECONDARY_INSTALLING: {
        name: 'New secondary installing',
        description: 'New secondary controller installation is in progress',
    },
    NEW_SECONDARY_INSTALLING_AFTER_REBOOT: {
        name: 'New secondary installing after reboot',
        description: 'Following a reboot, new secondary controller installation is in progress',
    },
    NEW_SECONDARY_INSTALLED: {
        name: 'New secondary installed',
        description: 'New secondary controller installation is complete',
    },

    POST_CHECKING: {
        name: 'Post checking',
        description: 'Final upgrade checks are in progress',
    },
    POST_CHECKS_FINISHED: {
        name: 'Post checks finished',
        description: 'Final upgrade checks are complete',
    },

    UPGRADING_DFS: {
        name: 'Upgrading DFS',
        description: 'Upgrade of DFS is in progress',
    },
    UPGRADED_DFS: {
        name: 'Upgraded DFS',
        description: 'Upgrade of DFS is complete',
    },

    UPGRADING_OFFLOAD_APP: {
        name: 'Upgrading the Offload application',
        description: 'Upgrade of the Offload application is in progress',
    },
    UPGRADED_OFFLOAD_APP: {
        name: 'Upgraded the Offload application',
        description: 'Upgrade of the Offload application is complete',
    },

    FINISHING: {
        name: 'Finishing',
        description: 'Installation is completing',
    },
    FINISHED: {
        name: 'Finished',
        description: 'Installation is complete',
    },

    // check failures: currently unused (error path needs further testing)
    PRE_CHECKS_FAILED: {
        name: 'Pre-upgrade checks failed',
        description: 'Upgrade prechecks on the array failed',
    },
    MID_CHECKS_FAILED: {
        name: 'Mid-upgrade checks failed',
        description: 'Upgrade midpoint checks on the array failed',
    },
    NEW_SECONDARY_PRE_CHECKS_FAILED: {
        name: 'New secondary pre-upgrade checks failed',
        description: 'Upgrade prechecks on the new secondary controller failed',
    },
    POST_CHECKS_FAILED: {
        name: 'Post-upgrade checks failed',
        description: 'Upgrade post checks on the array failed',
    },

    // installation failures: currently unused (error path needs further testing)
    SECONDARY_INSTALLATION_FAILED: {
        name: 'Secondary installation failed',
        description: 'Installation on the secondary controller failed',
    },
    NEW_SECONDARY_INSTALLATION_FAILED: {
        name: 'New secondary installation failed',
        description: 'Installation on the new secondary controller failed',
    },

    // alternate path
    ABORTING: {
        name: 'Aborting',
        description: 'Installation on the array is aborting',
    },
    ABORTING_REVERTING_SECONDARY: {
        name: 'Reverting to secondary during abort',
        description: 'Installation on the array is aborting, reverting the installation on secondary controller',
    },
    ABORTING_REVERTED_SECONDARY: {
        name: 'Reverted secondary during abort',
        description: 'Installation on the array is aborting, reverted the installation on secondary controller',
    },

    ABORTING_DUE_TO_ERROR: {
        name: 'Aborting due to error',
        description: 'Installation on the array is aborting due to error',
    },

    ABORTED_DUE_TO_ERROR: {
        name: 'Aborted due to error',
        description: 'Installation on the array is aborted due to error',
    },

    ABORTING_REBOOTING_ORIGINAL_SECONDARY: {
        name: 'Rebooting old secondary during abort',
        description: 'Installation on the array is aborting, rebooting original secondary controller',
    },
    ABORTING_REBOOTED_ORIGINAL_SECONDARY: {
        name: 'Rebooted old secondary during abort',
        description: 'Installation on the array is aborting, rebooted original secondary controller',
    },
    ABORTED: {
        name: 'Aborted',
        description: 'Installation on the array has been aborted',
    },

    // other statuses
    UNDEFINED: {
        name: 'Undefined',
        description: 'Installation state on the array is undefined (was not received)',
    },
    UNKNOWN: {
        name: 'Unknown',
        description: 'Installation state on the array entered an unknown state (implementation needed)',
    },
};

export enum InstallStatusNative {
    ABORTING = 'ABORTING',
    ABORTED = 'ABORTED',
    CHECKING = 'CHECKING',
    DOWNLOADING = 'DOWNLOADING',
    DOWNLOADED = 'DOWNLOADED',
    FINISHED = 'FINISHED',
    INSTALLING = 'INSTALLING',
    PAUSED = 'PAUSED',
    UNDEFINED = 'UNDEFINED',
    UNKNOWN = 'UNKNOWN',
}

export interface InstallUpgradeWorkflowInstance extends UpgradeWorkflowInstance {
    installStatus: InstallStatus;
    installId: number;
    installUuid: string;
    installStartTime: Date | moment.Moment;
    installEndTime: Date | moment.Moment;
    installDuration: Duration;
    installName: string;
    installMode: string;
    installMethod: string;
    installStatusNative: InstallStatusNative;
    details: string;
    lastEventTime: moment.Moment;
    checkOverrides: InstallCheckOverride[];
    overriddenChecksNext: string[];
    checkLists: InstallCheckList[];
    statuses: InstallUpgradeWorkflowInstanceStatus[];
}

export enum InstallCheckOverrideDurability {
    STEP = 'STEP',
    HOP = 'HOP',
    CHECK_STEP = 'CHECK_STEP',
    INSTALLATION = 'INSTALLATION',
}

export interface InstallCheckOverride {
    id: number;
    workflowInstanceId: number;
    name: string;
    hopVersion: string;
    checkStep: InstallCheckListStep;
    durability: InstallCheckOverrideDurability;
    arguments: string;
    comments: string;
    createdBy: string;
    createTime: moment.Moment;
    lastUpdateTime: moment.Moment;
}

export interface InstallUpgradeWorkflowInstanceStatus {
    id: number;
    workflowInstanceId: number;
    createTime: moment.Moment;
    lastUpdateTime: moment.Moment;
    index: number;
    installStatus: InstallStatus;
    installStatusNative: InstallStatusNative;
    hopVersion: string;
}

export interface InstallCheckList {
    id: number;
    workflowInstanceId: number;
    cfwdId: number;
    hopVersion: string;
    checkStep: InstallCheckListStep;
    createTime: moment.Moment;
    lastUpdateTime: moment.Moment;
    status: 'PASSED' | 'FAILED' | 'FAILED_OVERRIDABLE';
    checks: InstallCheck[];
}

export enum InstallCheckListStep {
    PRE = 'PRE',
    MID = 'MID',
    POST = 'POST',
}

export interface InstallCheck extends Check {
    checkListId: number;
    cfwdId: number;
    createTime: Date | moment.Moment;
    lastUpdateTime: Date | moment.Moment;
    autoOverridden: boolean;
}

export interface DownloadUpgradeWorkflowInstance extends UpgradeWorkflowInstance {
    downloadDuration: number;
    downloadEndTime: moment.Moment;
    cfwdId: number;
    downloadProgress: number;
    downloadRegistrationTime: moment.Moment;
    downloadStartDelay: number;
    downloadSize: number;
    dowloadStartTime: moment.Moment;
    downloadStatus: DownloadStatus;
}

export interface PDUWorkflowCreateRequest extends UpgradeWorkflowCreateRequest {
    supportCaseId: string;
    targetTime: Date | moment.Moment;
    type: WorkflowCreateRequestType.PDU;
}

export interface CDUWorkflowCreateRequest extends UpgradeWorkflowCreateRequest {
    type: WorkflowCreateRequestType.CDU;
    upgradePath: string[];
}

export interface PatchWorkflowCreateRequest extends WorkflowCreateRequest {
    type: WorkflowCreateRequestType.PATCH;
    patchName: string;
    arrayId: string;
}

export interface PrecheckWorkflowCreateRequest extends WorkflowCreateRequest {
    type: WorkflowCreateRequestType.PRECHECK;
    arrayId: string;
    startVersion: string;
    targetVersion: string;
    upgradePath: string[];
}

export enum WorkflowEventType {
    AbortWorkflow = 'AbortWorkflow',
    FinishWorkflow = 'FinishWorkflow',
    DataUpdate = 'DataUpdate',
    StartCloudPrecheck = 'StartCloudPrecheck',
    StartOnarrayPrecheckAsync = 'StartOnarrayPrecheckAsync',
    AbortDownload = 'AbortDownload',
    StartInstallationAsync = 'StartInstallationAsync',
    ContinueInstallationAsync = 'ContinueInstallationAsync',
    RetryInstallationStepAsync = 'RetryInstallationStepAsync',
    FinishInstallation = 'FinishInstallation',
    AbortInstallation = 'AbortInstallation',
    DownloadFinished = 'DownloadFinished',
    RemoveDownload = 'RemoveDownload',
    RefreshInstallationState = 'RefreshInstallationState',
    OverrideCheck = 'OverrideCheck',
    UpgradeToSelfService = 'UpgradeToSelfService',
    StartPatchInstallation = 'StartPatchInstallation',
    AbortPatchInstallation = 'AbortPatchInstallation',
    StartDownloadAsync = 'StartDownloadAsync',
}

export interface WorkflowEvent {
    type: WorkflowEventType;
}

export interface AbortDownloadWorkflowEvent extends WorkflowEvent {
    type: WorkflowEventType.AbortDownload;
}

export interface AbortInstallationWorkflowEvent extends WorkflowEvent {
    type: WorkflowEventType.AbortInstallation;
}

export interface AbortWorkflowEvent extends WorkflowEvent {
    type: WorkflowEventType.AbortWorkflow;
}

export interface ContinueInstallationAsyncWorkflowEvent extends WorkflowEvent {
    type: WorkflowEventType.ContinueInstallationAsync;
}

export interface RefreshInstallationStateWorkflowEvent extends WorkflowEvent {
    type: WorkflowEventType.RefreshInstallationState;
}

export interface RemoveDownloadWorkflowEvent extends WorkflowEvent {
    type: WorkflowEventType.RemoveDownload;
}

export interface DataUpdateWorkflowEvent extends WorkflowEvent {
    type: WorkflowEventType.DataUpdate;
    data: any;
    dataClass: any;
}

export interface FinishInstallationWorkflowEvent extends WorkflowEvent {
    type: WorkflowEventType.FinishInstallation;
}

export interface OverrideCheckWorkflowEvent extends WorkflowEvent {
    type: WorkflowEventType.OverrideCheck;
    name: string;
    durability: InstallCheckOverrideDurability;
    arguments: string;
    comment: string;
}

export interface FinishWorkflowEvent extends WorkflowEvent {
    type: WorkflowEventType.FinishWorkflow;
}

export interface RetryInstallationStepAsyncWorkflowEvent extends WorkflowEvent {
    type: WorkflowEventType.RetryInstallationStepAsync;
}

export interface StartCloudPrecheckWorkflowEvent extends WorkflowEvent {
    type: WorkflowEventType.StartCloudPrecheck;
}

export interface StartDownloadAsyncWorkflowEvent extends WorkflowEvent {
    type: WorkflowEventType.StartDownloadAsync;
    downloadMethod: 'Indirect' | 'Direct';
    existingDownloadBehavior?: 'REUSE' | 'REMOVE' | 'IGNORE';
}

export interface StartInstallationAsyncWorkflowEvent extends WorkflowEvent {
    type: WorkflowEventType.StartInstallationAsync;
}

export interface UpgradeToSelfServiceWorkflowEvent extends WorkflowEvent {
    type: WorkflowEventType.UpgradeToSelfService;
    targetVersion: string;
    upgradePath: string[];
}

export interface StartPatchInstallationEvent extends WorkflowEvent {
    type: WorkflowEventType.StartPatchInstallation;
}

export interface AbortPatchInstallationEvent extends WorkflowEvent {
    type: WorkflowEventType.AbortPatchInstallation;
}

export interface WorkflowState {
    instance: UpgradeWorkflowInstance;
    nextEventTypes: WorkflowEventType[];
    arrayInfo: ArrayStatus;
}

export interface StepUpAuthRequirements {
    acr: string;
    amr: string;
    aud: string;
    authorizationDetails: AuthorizationDetails[];
    singleUse: boolean;
    ttl: number;
}

export interface AuthorizationDetails {
    action: string[];
    type: string;
    arrayId: string;
}

export interface UpgradeVersion {
    arrayId: string;
    number: string;
    family: string;
    restricted: boolean;
    recommended: boolean;
    recommendedDetail: string;
    upgradePath: [];
}

export interface UpgradePath {
    arrayId: string;
    message?: string;
    upgradeVersions: string[];
    upgradeSteps: UpgradeStep[];
    upgradeDurationSeconds: number;
}

export interface AvailablePatch {
    arrayId: string;
    createTime: moment.Moment;
    description: string;
    latest: string;
    message: string;
    name: string;
    patchId: number;
    resolvedCVEs: string[];
}

export interface UpgradeStep {
    sourceVersion: string;
    targetVersion: string;
    upgradeType: string;
}

export interface CduAppliance {
    applianceId: string;
    applianceUpgradeStatus?: ApplianceUpgradeStatus;
    assistedUpgradeCaseId?: string;
    assistedUpgradeCaseNumber?: string;
    assistedUpgradeCaseParentId?: string;
    currentVersion: string;
    edgeAgentUpdateEnabled: boolean;
    edgeConnectType: EdgeConnectType;
    edgeManagementEnabled: boolean;
    edgeStatus: EdgeStatus;
    freqDiagAvailable: boolean;
    hostname: string;
    id: number;
    lastPrecheckFailureCount: number;
    lastPrecheckSuccessful: boolean;
    lastWorkflowId?: number;
    lastWorkflowName: WorkflowCreateRequestType;
    lastWorkflowStatus: WorkflowInstanceStatus;
    lastPrecheckWorkflowId?: number;
    lastPrecheckWorkflowStatus?: WorkflowInstanceStatus;
    policy: Policy;
    statusTime?: moment.Moment;
    targetVersion: string;
    precheckTargetVersion: string;
    replications?: Replication[];
    lastFinishedPatchWorkflowId?: number;
    lastFinishedUpgradeWorkflowId?: number;
    hardwareModel?: string;
    autoDownloadEnabled?: boolean;
    activeClusterTargetAplianceIds?: string[];
    activeClusterStatus?: ActiveClusterStatus;
    availablePatches?: AvailablePatch[];
    policyRecommendedVersion?: string;
    policyRecommendedReleaseNotesUrl?: string;
    domain: string;
    supportCaseId?: string;
    contractStatus?: ContractStatus;
    softwareName?: ApplianceSotwareName;
    selfServiceStatus?: SelfServiceStatus;
}

export enum ApplianceSotwareName {
    PurityFB = 'Purity//FB',
    PurityFA = 'Purity//FA',
    PurityFAAZ = 'Purity//FA AZ',
    PurityFAAWS = 'Purity//FA AWS',
}

export enum SelfServiceStatus {
    CONFIGURED = 'CONFIGURED',
    NOT_CONFIGURED = 'NOT_CONFIGURED',
    NOT_SUPPORTED = 'NOT_SUPPORTED',
}

export interface CduApplianceStats {
    totalElements: number;
    ssuEligibleTotal: number;
    actionRequiredTotal: number;
    securityUpdateAvailableTotal: number;
    upgradeRecommendedTotal: number;
    installationsInProgressTotal: number;
    supportCaseOpenTotal: number;
    upgradeScheduledTotal: number;
    appliancesTotal: number;
    contractStatusExpiredTotal: number;
    supporterAvailable: boolean;
}

export type CduApplianceResponse = { content: CduAppliance[] } & CduApplianceStats;

export enum ApplianceUpgradeStatus {
    DOWNLOAD_READY = 'DOWNLOAD_READY',
    DOWNLOAD_COMPLETED_ABORT = 'DOWNLOAD_COMPLETED_ABORT',
    DOWNLOAD_COMPLETED_FAIL = 'DOWNLOAD_COMPLETED_FAIL',
    DOWNLOAD_COMPLETED_SUCCESS = 'DOWNLOAD_COMPLETED_SUCCESS',
    DOWNLOAD_IN_PROGRESS = 'DOWNLOAD_IN_PROGRESS',
    HEALTH_CHECK_COMPLETED_FAIL = 'HEALTH_CHECK_COMPLETED_FAIL',
    HEALTH_CHECK_COMPLETED_SUCCESS = 'HEALTH_CHECK_COMPLETED_SUCCESS',
    HEALTH_CHECK_IN_PROGRESS = 'HEALTH_CHECK_IN_PROGRESS',
    CLOUD_HEALTH_CHECK_COMPLETED_FAIL = 'CLOUD_HEALTH_CHECK_COMPLETED_FAIL',
    CLOUD_HEALTH_CHECK_IN_PROGRESS = 'CLOUD_HEALTH_CHECK_IN_PROGRESS',
    CLOUD_HEALTH_CHECK_COMPLETED_SUCCESS = 'CLOUD_HEALTH_CHECK_COMPLETED_SUCCESS',
    CLOUD_HEALTH_CHECK_COMPLETED_WARNING = 'CLOUD_HEALTH_CHECK_COMPLETED_WARNING',
    ON_ARRAY_HEALTH_CHECK_COMPLETED_FAIL = 'ON_ARRAY_HEALTH_CHECK_COMPLETED_FAIL',
    ON_ARRAY_HEALTH_CHECK_IN_PROGRESS = 'ON_ARRAY_HEALTH_CHECK_IN_PROGRESS',
    ON_ARRAY_HEALTH_CHECK_COMPLETED_WARNING = 'ON_ARRAY_HEALTH_CHECK_COMPLETED_WARNING',
    ON_ARRAY_HEALTH_CHECK_COMPLETED_SUCCESS = 'ON_ARRAY_HEALTH_CHECK_COMPLETED_SUCCESS',
    INSTALLATION_COMPLETED_ABORT = 'INSTALLATION_COMPLETED_ABORT',
    INSTALLATION_COMPLETED_SUCCESS = 'INSTALLATION_COMPLETED_SUCCESS',
    INSTALLATION_IN_PROGRESS = 'INSTALLATION_IN_PROGRESS',
    INSTALLATION_ABORTING = 'INSTALLATION_ABORTING',
    INSTALLATION_COMPLETED_ABORT_DUE_ERROR = 'INSTALLATION_COMPLETED_ABORT_DUE_ERROR',
    INSTALLATION_PAUSED = 'INSTALLATION_PAUSED',
    INSTALLATION_PENDING = 'INSTALLATION_PENDING',
    INSTALLATION_FIRST_CONTROLLER_DONE = 'INSTALLATION_FIRST_CONTROLLER_DONE',
    INSTALLATION_PRECHECK_WARNING = 'INSTALLATION_PRECHECK_WARNING',
    PATCH_IN_PROGRESS = 'PATCH_IN_PROGRESS',
    PATCH_CREATED = 'PATCH_CREATED',
    NONE = 'NONE',
    UP_TO_DATE = 'UP_TO_DATE',
    SSU_READY = 'SSU_READY',
}

export enum ApplianceUpgradeStatusLabel {
    DOWNLOAD_READY = 'Ready for download',
    DOWNLOAD_COMPLETED_ABORT = 'Download canceled',
    DOWNLOAD_COMPLETED_FAIL = 'Download error',
    DOWNLOAD_COMPLETED_SUCCESS = 'Download completed',
    DOWNLOAD_IN_PROGRESS = 'Download in progress',
    HEALTH_CHECK_COMPLETED_FAIL = 'Health check error',
    HEALTH_CHECK_COMPLETED_SUCCESS = 'Health check completed',
    HEALTH_CHECK_IN_PROGRESS = 'Health check in progress',
    CLOUD_HEALTH_CHECK_COMPLETED_FAIL = 'Health check error',
    CLOUD_HEALTH_CHECK_IN_PROGRESS = 'Health check in progress',
    CLOUD_HEALTH_CHECK_COMPLETED_SUCCESS = 'Health check completed',
    ON_ARRAY_HEALTH_CHECK_COMPLETED_FAIL = 'Health check error',
    ON_ARRAY_HEALTH_CHECK_IN_PROGRESS = 'Health check in progress',
    ON_ARRAY_HEALTH_CHECK_COMPLETED_SUCCESS = 'Health check completed',
    INSTALLATION_COMPLETED_ABORT = 'Installation canceled',
    INSTALLATION_COMPLETED_ABORT_DUE_ERROR = 'Installation canceled due to error',
    INSTALLATION_COMPLETED_SUCCESS = 'Installation completed',
    INSTALLATION_IN_PROGRESS = 'Installation in progress',
    INSTALLATION_ABORTING = 'Installation is being canceled',
    INSTALLATION_PAUSED = 'Installation paused',
    PATCH_IN_PROGRESS = 'Patch installation in progress',
    PATCH_CREATED = 'Authentication not completed',
    NONE = 'None',
}

export enum Policy {
    BALANCED = 'BALANCED',
    ESSENTIAL = 'ESSENTIAL',
    PROACTIVE = 'PROACTIVE',
}

export enum PolicyLabel {
    BALANCED = 'Balanced',
    ESSENTIAL = 'Essential',
    PROACTIVE = 'Proactive',
}

export interface ArrayStatus {
    autoDownloadEnabled: boolean;
    edgeStatus: EdgeStatus;
    edgeConnectType: EdgeConnectType;
    edgeManagementEnabled: boolean;
    edgeAgentUpdateEnabled: boolean;
    remoteAssistEnabled: boolean;
    replications?: Replication[];
    currentVersion: string;
}

export interface Replication {
    type: ReplicationType;
    objectName: string;
    sourceName: string;
    targetName: string;
}

export interface UserCheckOverride {
    checkName: string;
    comment: string;
}

export enum EdgeConnectType {
    DIRECT_SSH = 'DIRECT_SSH',
    DIRECT_REST = 'DIRECT_REST',
    DIRECT_GRPC = 'DIRECT_GRPC',
    EMS_GRPC = 'EMS_GRPC',
    REMOTE_ASSIST = 'REMOTE_ASSIST',
    FORBIDDEN = 'FORBIDDEN',
    SIMULATED = 'SIMULATED',
}

export enum ReplicationType {
    ASYNC = 'ASYNC',
    ACTIVE_DR = 'ACTIVE_DR',
    ACTIVE_CLUSTER = 'ACTIVE_CLUSTER',
}

export enum EdgeStatus {
    AGENT_INSTALL_FAILED = 'AGENT_INSTALL_FAILED',
    AGENT_INSTALL_PENDING = 'AGENT_INSTALL_PENDING',
    AGENT_NEEDS_UPDATE = 'AGENT_NEEDS_UPDATE',
    AGENT_NOT_INSTALLED = 'AGENT_NOT_INSTALLED',
    AGENT_UNHEALTHY = 'AGENT_UNHEALTHY',
    AGENT_UNINSTALL_FAILED = 'AGENT_UNINSTALL_FAILED',
    AGENT_UNINSTALL_PENDING = 'AGENT_UNINSTALL_PENDING',
    AGENT_UNKNOWN = 'AGENT_UNKNOWN',
    AGENT_UNKNOWN_HEALTH = 'AGENT_UNKNOWN_HEALTH',
    CONNECTED = 'CONNECTED',
    NEEDS_UPGRADE = 'NEEDS_UPGRADE',
    NOT_ENABLED = 'NOT_ENABLED',
    OFFLINE = 'OFFLINE',
    UNHEALTHY = 'UNHEALTHY',
    UNKNOWN = 'UNKNOWN',
    UNSUPPORTED = 'UNSUPPORTED',
}

export enum EdgeStatusLabel {
    AGENT_INSTALL_FAILED = 'Agent install failed',
    AGENT_INSTALL_PENDING = 'Agent install pending',
    AGENT_NEEDS_UPDATE = 'Agent needs update',
    AGENT_NOT_INSTALLED = 'Agent not installed',
    AGENT_UNHEALTHY = 'Agent unhealthy',
    AGENT_UNINSTALL_FAILED = 'Agent uninstall failed',
    AGENT_UNINSTALL_PENDING = 'Agent uninstall pending',
    AGENT_UNKNOWN = 'Agent unknown',
    AGENT_UNKNOWN_HEALTH = 'Agent unknown health',
    CONNECTED = 'Configured',
    NEEDS_UPGRADE = 'Edge Service needs upgrade',
    NOT_ENABLED = 'Edge Service not enabled',
    OFFLINE = 'Edge Service offline',
    UNHEALTHY = 'Edge Service unhealthy',
    UNKNOWN = 'Eligible, not configured',
    UNSUPPORTED = 'Not Configured',
}

export interface EdgeStatusMessageWithIcon {
    message: string;
    icon: string;
}

export enum ActiveClusterStatus {
    NONE = 'NONE',
    SYNCED = 'SYNCED',
    PAIRED_ARRAY_NOT_VISIBLE = 'PAIRED_ARRAY_NOT_VISIBLE',
    MULTI_SYNCED = 'MULTI_SYNCED',
}

export interface Download {
    id: number;
    payload: PayloadMetaData[];
}

export interface PayloadMetaData {
    description: string;
    label: string;
    fileNames: string[];
    payloadMetaDataType: string;
    size: number;
}

export const isUpgradeWorkflowInstance = (
    instance: WorkflowInstance | UpgradeWorkflowInstance,
): instance is UpgradeWorkflowInstance => {
    return instance.model === WorkflowInstanceModel.Upgrade;
};

export const isDownloadUpgradeWorkflowInstance = (
    instance: WorkflowInstance | DownloadUpgradeWorkflowInstance,
): instance is DownloadUpgradeWorkflowInstance => {
    return instance.model === WorkflowInstanceModel.DownloadUpgrade;
};

export const isInstallUpgradeWorkflowInstance = (
    instance: WorkflowInstance | InstallUpgradeWorkflowInstance,
): instance is InstallUpgradeWorkflowInstance => {
    return instance.model === WorkflowInstanceModel.InstallUpgrade;
};

export const isPatchWorkflowInstance = (
    instance: WorkflowInstance | PatchWorkflowInstance,
): instance is PatchWorkflowInstance => {
    return instance.model === WorkflowInstanceModel.Patch;
};

export const isPDUWorkflowCreateRequest = (
    request: WorkflowCreateRequest | PDUWorkflowCreateRequest,
): request is PDUWorkflowCreateRequest => {
    return request.type === WorkflowCreateRequestType.PDU;
};

export const isInstalling = (instance: WorkflowInstance): boolean => {
    if (!instance) {
        return false;
    }

    if (!isUpgradeWorkflowInstance(instance)) {
        return false;
    }

    return [
        UpgradeWorkflowInstanceStatus.INSTALLING,
        UpgradeWorkflowInstanceStatus.INSTALLED,
        UpgradeWorkflowInstanceStatus.INSTALLATION_ABORTED,
        UpgradeWorkflowInstanceStatus.INSTALLATION_FAILED,
        UpgradeWorkflowInstanceStatus.FAILED,
        UpgradeWorkflowInstanceStatus.PENDING,
    ].some(x => x === instance.upgradeStatus);
};

export interface BulkPrecheckJob {
    createdBy: string;
    createTime: moment.Moment;
    id: number;
    precheckJobs: CloudPrecheck[];
    onArrayPrecheckJobs: OnArrayPrecheckJob[];
}

export interface BulkPrecheckPreflightRequest {
    arrayId: string;
    newVersion: string;
}

export interface BulkPrecheckJobRequestModel {
    arrayId: string;
    currentVersion: string;
    newVersion: string;
    type: BulkPrecheckJobRequestType;
    upgradePath: string[];
}

export enum BulkPrecheckJobRequestType {
    CLOUD = 'CLOUD',
    ON_ARRAY = 'ON_ARRAY',
}

export interface FilterTextChange {
    key: string;
    entity: string;
    values: string[];
}

export enum CloudPrecheckUiName {
    SSU = 'SSU',
    PURE1 = 'PURE1',
}

export interface UpgradePoliciesModel {
    code: Policy;
    displayName: string;
}

export interface ChangePolicyResult {
    arrayId: string;
    policy: Policy;
}

export interface PolicyVersionsModel {
    inPolicyVersions: PolicyVersionData[];
    outOfPolicyVersions: PolicyVersionData[];
}

export interface PolicyVersionData {
    lifecycleState: LifecycleState;
    supportState: SupportState;
    version: string;
}

export interface versionWithPolicyStatus {
    version: string;
    outOfPolicy: boolean;
    releaseNotesUrl: string;
}

export enum ApplianceOverviewView {
    PURITY_UPGRADE = 'Purity Upgrade',
    HEALTH_CHECKS = 'Health Checks',
    SECURITY_UPDATE = 'Security Update',
    OVERVIEW = 'Overview',
    UPGRADE_REPORT = 'Upgrade Report',
    HEALTH_CHECK_RESULTS = 'Health Check Results',
    INSTALLATION_REPORT = 'Installation Report',
}

export enum InstallCheckListStepDisplayText {
    PRE = 'Precheck',
    MID = 'Midpoint',
    POST = 'Post Upgrade',
}

export interface WorkflowStateWithUpdateSynchronizedFlag {
    workflow: WorkflowState;
    updateSynchronizedWorkflows: boolean;
}

export enum BulkActions {
    PRECHECKS = 'PRECHECKS',
    DOWNLOAD = 'DOWNLOAD',
    START_INSTALLATION = 'START_INSTALLATION',
}

export interface BulkHistory {
    id: string;
    orgId: string;
    createTime: moment.Moment;
    eventType: WorkflowEventType;
    applianceIds: string[];
    rootWorkflowIds: string[];
}

export interface BulkHistoryPayload {
    entries: BulkHistory[];
}

export const BulkHistoryEventTypeDescription: { [key in WorkflowEventType]: string } = {
    [WorkflowEventType.AbortWorkflow]: 'Abort installation',
    [WorkflowEventType.FinishWorkflow]: 'Finish installation',
    [WorkflowEventType.DataUpdate]: 'Data update',
    [WorkflowEventType.StartCloudPrecheck]: 'Start precheck',
    [WorkflowEventType.AbortDownload]: 'Abort download',
    [WorkflowEventType.StartInstallationAsync]: 'Start installation',
    [WorkflowEventType.ContinueInstallationAsync]: 'Continue installation',
    [WorkflowEventType.RetryInstallationStepAsync]: 'Retry installation step',
    [WorkflowEventType.FinishInstallation]: 'Finish installation',
    [WorkflowEventType.AbortInstallation]: 'Abort installation',
    [WorkflowEventType.DownloadFinished]: 'Download finished',
    [WorkflowEventType.RemoveDownload]: 'Remove download',
    [WorkflowEventType.RefreshInstallationState]: 'Refresh installation state',
    [WorkflowEventType.OverrideCheck]: 'Override check',
    [WorkflowEventType.UpgradeToSelfService]: 'Upgrade to self service',
    [WorkflowEventType.StartPatchInstallation]: 'Start patch installation',
    [WorkflowEventType.AbortPatchInstallation]: 'Abort patch installation',
    [WorkflowEventType.StartDownloadAsync]: 'Start download',
    [WorkflowEventType.StartOnarrayPrecheckAsync]: 'Start precheck',
};

export enum applianceStatFilter {
    ACTION_REQUIRED = 'actionRequired',
    SECURITY_UPDATE_AVAILABLE = 'securityUpdateAvailable',
    CONTRACT_STATUS_EXPIRED = 'contractStatus',
    UPGRADE_RECOMMENDED = 'upgradeRecommended',
    INSTALLATION_IN_PROGRESS = 'installationInProgress',
    OPEN_SUPPORT_CASES = 'supportCaseOpen',
    READY_FOR_SSU = 'readyForSSU',
    UPGRADE_SCHEDULED = 'upgradeScheduled',
}

export enum ContractStatus {
    EXPIRED = 'EXPIRED',
    ACTIVE = 'ACTIVE',
}
