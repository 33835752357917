import { Inject, Injectable, OnDestroy } from '@angular/core';
import cryptoJS from 'crypto-js';
import { Observable, of, Subject } from 'rxjs';
import moment from 'moment';
import { catchError, map, take, takeUntil, tap } from 'rxjs/operators';
import { WINDOW } from '../app/injection-tokens';
import { createAuthPortalLoginPath } from '../utils/url';
import { PlatformClientService } from '@pure/pure1-ui-platform-angular';

const INTROSPECT_INTERVAL = moment.duration(2, 'minutes').asMilliseconds();
const SALT = 'KheZYbVOe4';

export interface Oauth2Introspect {
    active: boolean;
    email?: string;
    act?: {
        email: string;
    };
    aud: string;
    ph_oid: number;
}

@Injectable({ providedIn: 'root' })
export class AuthenticationService implements OnDestroy {
    basekeyGenerated$ = new Subject<string>();
    private destroy$ = new Subject<void>();

    constructor(
        @Inject(WINDOW) private window: Window,
        private platformClientService: PlatformClientService,
    ) {
        this.platformClientService
            .getIntrospect()
            .pipe(
                take(1),
                catchError(_ => {
                    return of({ active: false } as Oauth2Introspect);
                }),
                tap(introspectResponse => {
                    const response = introspectResponse as Oauth2Introspect;
                    const hasActivePure1Token = response.active && response.aud == 'pure1';
                    if (hasActivePure1Token) {
                        if (response.email) {
                            this.window.pure1.basekey = cryptoJS.SHA1(response.email + SALT).toString(cryptoJS.enc.Hex);
                        }
                        this.handleImpersonation(response);
                    }
                    this.basekeyGenerated$.next(this.window.pure1.basekey);
                }),
                takeUntil(this.destroy$),
            )
            .subscribe();
    }

    getIntrospect(): Observable<Oauth2Introspect> {
        return this.platformClientService
            .getIntrospect()
            .pipe(map(introspectResponse => introspectResponse as any as Oauth2Introspect));
    }

    isLoggedIn(): Observable<boolean> {
        return this.platformClientService.getIsLoggedIn();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    /**
     * Handles impersonation switch flow when user is logged in, but URL contains new impersonation email.
     * In such cases we need to initiate new authentication flow through Auth Portal to get a new Impersonation Access Token.
     * @param response
     * @private
     */
    private handleImpersonation(response: Oauth2Introspect): void {
        // Need to switch impersonation if user is logged in and not impersonating the proper user
        const originalUrlParams = new URLSearchParams(this.window.location.search);
        const impersonateEmail = originalUrlParams.get('impersonate');

        if (impersonateEmail) {
            if (impersonateEmail.toLowerCase() != response.email.toLowerCase()) {
                originalUrlParams.delete('impersonate');

                const actorEmail = response.act ? response.act.email : response.email;
                const rawPage = this.window.location.pathname + '?' + originalUrlParams.toString();

                const path = createAuthPortalLoginPath(actorEmail, impersonateEmail, rawPage);

                this.window.location.href = this.window.location.origin + path;
            }
        }
    }
}
