import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { AuthorizationService } from '@pure/authz-authorizer';

import {
    ArrayContractStatus,
    FeatureFlagDxpService,
    ResourceStatus,
    ServiceCatalogQuote,
    UnifiedArray,
} from '@pure1/data';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { FeatureNames } from '../../model/FeatureNames';
import { PureArray } from '../../model/model';
import { addSort, LOCAL_BAR_ARRAYS, removeAllSorts } from '../../redux/actions';
import { NgRedux } from '../../redux/ng-redux.service';
import { IState } from '../../redux/pure-redux.service';
import { ArraysManager } from '../../services/arrays-manager.service';
import { EolHardwareService, EolOpportunityStatus } from '../../services/eol-hardware.service';
import { sum } from '../../utils/math';
import { AppliancesViewMode } from '../appliances-view/appliances-view.component';
import { AssetSortRuleId, AssetSortRules, SortRules } from '../services/asset-sort-rules.service';

type ListColumnType = 'value' | 'capacity' | 'alert' | 'array-name' | 'eol';

type EolRequestStatus = 'not_started' | 'in_progress' | 'done' | 'error';

interface IPureArrayListColumn {
    title: string;
    sizeWeight: number;
    type: ListColumnType;
    sortRuleId: AssetSortRuleId | null;

    getValue(array: UnifiedArray): string;

    showColumn?: (arrays: UnifiedArray[]) => boolean;
}

@Component({
    selector: 'arrays-list-table',
    templateUrl: 'arrays-list-table.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArraysListTableComponent implements OnInit, OnChanges, OnDestroy {
    @Input() readonly items: UnifiedArray[];
    @Input() readonly alertMap: Map<string, IAlert[]>;
    @Input() readonly allOrders: ServiceCatalogQuote[];
    @Input() readonly showWarningCardForOutOfSupportAppliance: boolean = true; // defaults to true
    @Input() readonly mode: AppliancesViewMode;

    readonly ResourceStatus = ResourceStatus;
    readonly ArrayContractStatusExpired = ArrayContractStatus.EXPIRED;

    tableConfig: IPureArrayListColumn[];
    sortRules: SortRules<UnifiedArray>;
    barIdMode = LOCAL_BAR_ARRAYS;
    sortRuleId: AssetSortRuleId;
    sortDesc: boolean;
    selectedArrayExistingQuote: ServiceCatalogQuote;
    hwEOLEnabled = false;
    hwEolRequestStatus: Map<string, EolRequestStatus> = new Map();
    hwEolIncidentOpened: Map<string, boolean> = new Map();
    capacityDownLicensingEnabled = false;
    initialized = false;
    eocEnabled = false;

    private unsubscribeFromRedux: Function;
    private readonly destroy$ = new Subject<void>();
    hasContactAccountTeamAccess: boolean = false;

    constructor(
        public arraysManager: ArraysManager,
        private assetSortRules: AssetSortRules,
        private cdr: ChangeDetectorRef,
        private ngRedux: NgRedux<IState>,
        private featureFlagDxpService: FeatureFlagDxpService,
        private eolHardwareService: EolHardwareService,
        private authorizationService: AuthorizationService,
    ) {}

    ngOnInit(): void {
        this.unsubscribeFromRedux = this.ngRedux.subscribe(() => this.handleRedux());
        this.handleRedux();

        this.featureFlagDxpService
            .getFeatureFlag(FeatureNames.HW_EOL)
            .pipe(take(1))
            .subscribe(feature => {
                this.hwEOLEnabled = feature?.enabled === true;
            });
        this.featureFlagDxpService
            .getFeatureFlag(FeatureNames.EOC)
            .pipe(take(1))
            .subscribe(feature => {
                this.eocEnabled = feature?.enabled;
            });

        this.eolHardwareService.opportunitiesUpdated$.pipe(takeUntil(this.destroy$)).subscribe(() => {
            if (this.items) {
                this.items.forEach(array => {
                    // update the request map
                    this.hwEolRequestStatus.set(
                        array.cloud_array_id,
                        this.convertOpportunityStatus(
                            this.eolHardwareService.isEolOpportunityOpened(array.cloud_array_id),
                        ),
                    );
                });
                this.cdr.markForCheck();
            }
        });

        const hasPermissionToViewRecommendation = this.eolHardwareService.hasPermissionToViewRecommendation();
        this.eolHardwareService.incidentsUpdated$.pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.items.forEach(array => {
                this.hwEolIncidentOpened.set(
                    array.cloud_array_id,
                    this.eolHardwareService.isEolIncidentOpened(array.cloud_array_id) &&
                        hasPermissionToViewRecommendation,
                );
            });
            this.cdr.markForCheck();
        });

        this.authorizationService
            .isAllowed('PURE1:write:support_cases')
            .pipe(takeUntil(this.destroy$))
            .subscribe(allowed => {
                this.hasContactAccountTeamAccess = allowed;
                this.cdr.markForCheck();
            });

        this.featureFlagDxpService
            .getFeatureFlag(FeatureNames.CAPACITY_DOWN_LICENSING_APPLIANCE)
            .pipe(take(1))
            .subscribe(feature => {
                this.capacityDownLicensingEnabled = feature?.enabled === true;
            });
        this.initialized = true;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.initialized) {
            this.tableConfig = this.capacityDownLicensingEnabled
                ? this.getFaFbCapacityDownEnabledConfig()
                : this.getFaFbConfig();
        }
        if (changes.mode) {
            switch (this.mode) {
                case 'array':
                    this.tableConfig = this.capacityDownLicensingEnabled
                        ? this.getFaFbCapacityDownEnabledConfig()
                        : this.getFaFbConfig();
                    this.sortRules = this.assetSortRules.getArraySort(() => this.alertMap);
                    this.barIdMode = LOCAL_BAR_ARRAYS;
                    break;
                default:
                    this.tableConfig = [];
                    this.sortRules = null;
                    this.barIdMode = '';
                    break;
            }
        }
        if (changes.items) {
            const hasPermissionToViewRecommendation = this.eolHardwareService.hasPermissionToViewRecommendation();
            this.items.forEach(array => {
                if (!this.hwEolRequestStatus.has(array.cloud_array_id)) {
                    this.hwEolRequestStatus.set(
                        array.cloud_array_id,
                        this.convertOpportunityStatus(
                            this.eolHardwareService.isEolOpportunityOpened(array.cloud_array_id),
                        ),
                    );
                }

                this.hwEolIncidentOpened.set(
                    array.cloud_array_id,
                    this.eolHardwareService.isEolIncidentOpened(array.cloud_array_id) &&
                        hasPermissionToViewRecommendation,
                );
            });
            this.cdr.markForCheck();
        }
    }

    ngOnDestroy(): void {
        if (this.unsubscribeFromRedux) {
            this.unsubscribeFromRedux();
        }
        this.destroy$.next();
        this.destroy$.complete();
    }

    getArrayAlerts(array: UnifiedArray): IAlert[] {
        return this.alertMap && this.alertMap.get(array.id);
    }

    canShowArrayInfo(item: UnifiedArray): boolean {
        //get existing quote
        this.selectedArrayExistingQuote = this.arraysManager.getSupportContractRenewalQuote(item, this.allOrders);
        return !this.showWarningCardForOutOfSupportAppliance || this.arraysManager.isCardShowArrayInfo(item);
    }

    clickHeading(cell: IPureArrayListColumn): void {
        if (!cell.sortRuleId) {
            return;
        }

        // If clicking already-sorted column, flip sort order
        let sortDesc = this.sortDesc;
        if (cell.sortRuleId === this.sortRuleId) {
            sortDesc = !sortDesc;
        } else {
            sortDesc = false; // Otherwise, reset order to back to default (asc)
        }

        this.ngRedux.dispatch([
            removeAllSorts(this.barIdMode),
            addSort(this.barIdMode, cell.sortRuleId + (sortDesc ? '-' : '')),
        ]);
    }

    getCellValue(cell: IPureArrayListColumn, item: UnifiedArray): string {
        try {
            return cell.getValue(item);
        } catch {
            // To avoid having to make multiple null property checks in our getValue() funcs,
            // just return an empty string instead when we fail to get the value.
            return '';
        }
    }

    getDisconnectedCellWeight(): number {
        const excludeWeight = this.capacityDownLicensingEnabled
            ? this.tableConfig[0].sizeWeight + this.tableConfig[1].sizeWeight + this.tableConfig[2].sizeWeight
            : this.tableConfig[0].sizeWeight + this.tableConfig[1].sizeWeight;
        return sum(this.tableConfig.map(cell => cell.sizeWeight)) - excludeWeight;
    }

    getExpiredCellWeight(): number {
        const excludeWeight = this.capacityDownLicensingEnabled
            ? this.tableConfig[0].sizeWeight +
              this.tableConfig[1].sizeWeight +
              this.tableConfig[2].sizeWeight +
              this.tableConfig[3].sizeWeight +
              this.tableConfig[4].sizeWeight
            : this.tableConfig[0].sizeWeight +
              this.tableConfig[1].sizeWeight +
              this.tableConfig[2].sizeWeight +
              this.tableConfig[3].sizeWeight;
        return sum(this.tableConfig.map(cell => cell.sizeWeight)) - excludeWeight;
    }

    hasEolComponents(array: UnifiedArray): boolean {
        return this.hwEOLEnabled && array.has_end_of_life_hardware;
    }

    checkShowColumn(showColumn: (arrays: UnifiedArray[]) => boolean): boolean {
        return showColumn ? showColumn(this.items) : true;
    }

    clickContactAccountTeam(array: UnifiedArray): void {
        if (this.hasContactAccountTeamAccess) {
            this.hwEolRequestStatus.set(array.cloud_array_id, 'in_progress');
            this.eolHardwareService
                .createEolOpportunity(array.cloud_array_id, array.name, 'Pure1 Eol Appliance Table')
                .subscribe({
                    next: result => {
                        this.hwEolRequestStatus.set(array.cloud_array_id, 'done');
                        this.cdr.markForCheck();
                    },
                    error: error => {
                        this.hwEolRequestStatus.set(array.cloud_array_id, 'error');
                        this.cdr.markForCheck();
                    },
                });
        }
    }

    hasPermissionToViewRecommendation(): boolean {
        return this.eolHardwareService.hasPermissionToViewRecommendation();
    }

    clickViewRecommendation(array: UnifiedArray): void {
        this.eolHardwareService.viewRecommendation(array.cloud_array_id);
    }

    getFullColumnWidth(): number {
        return this.tableConfig.reduce(
            (acc, column) => acc + (this.checkShowColumn(column.showColumn) ? column.sizeWeight : 0),
            0,
        );
    }

    private convertOpportunityStatus(status: EolOpportunityStatus): EolRequestStatus {
        switch (status) {
            case 'opened':
                return 'done';
            case 'unopened':
                return 'not_started';
            case 'unknown':
                return 'in_progress';
            default:
                return 'not_started';
        }
    }

    private handleRedux(): void {
        // Fetch the sort state
        const state = this.ngRedux.getState();

        const sortState = (state.sorts[this.barIdMode] || [])[0] || '';
        this.sortRuleId = <AssetSortRuleId>(
            (sortState.endsWith('-') ? sortState.substr(0, sortState.length - 1) : sortState)
        ); // Remove minus for desc
        this.sortDesc = sortState.endsWith('-');

        this.cdr.markForCheck();
    }

    private getFaFbConfig(): IPureArrayListColumn[] {
        return [
            {
                title: '',
                sortRuleId: 'alertSeverity',
                sizeWeight: 0,
                type: 'alert',
                getValue: (array: PureArray) => '',
            },
            {
                title: 'Name',
                sortRuleId: 'hostname',
                sizeWeight: 3,
                type: 'array-name',
                getValue: (array: PureArray) => array.hostname,
            },
            {
                title: 'Model',
                sortRuleId: 'model',
                sizeWeight: 2,
                type: 'value',
                getValue: (array: PureArray) => array.model,
            },
            {
                title: 'Version',
                sortRuleId: 'version',
                sizeWeight: 2,
                type: 'value',
                getValue: (array: PureArray) => array.version,
            },
            {
                title: 'Protocol',
                sortRuleId: 'protocol',
                sizeWeight: 2,
                type: 'value',
                getValue: (array: PureArray) => array.protocolString,
            },
            {
                title: '% Full',
                sortRuleId: 'percFull',
                sizeWeight: 2,
                type: 'capacity',
                getValue: (array: PureArray) => array.capacityData.actualPercFull,
            },
            {
                title: 'Capacity',
                sortRuleId: 'capacity',
                sizeWeight: 2,
                type: 'value',
                getValue: (array: PureArray) => this.formatVU(array.capacityData.capacityStr),
            },
            {
                title: 'Provisioned',
                sortRuleId: 'provisioned',
                sizeWeight: 2,
                type: 'value',
                getValue: (array: PureArray) => this.formatVU(array.capacityData.provisionedStr),
            },
            {
                title: 'Reduction',
                sortRuleId: 'dataReduction',
                sizeWeight: 2,
                type: 'value',
                getValue: (array: PureArray) => array.capacityData.dataReductionStr,
            },
            {
                title: 'R Latency',
                sortRuleId: 'readLatency',
                sizeWeight: 2,
                type: 'value',
                getValue: (array: PureArray) => this.formatVU(array.performanceData.readLatencyStr),
            },
            {
                title: 'W Latency',
                sortRuleId: 'writeLatency',
                sizeWeight: 2,
                type: 'value',
                getValue: (array: PureArray) => this.formatVU(array.performanceData.writeLatencyStr),
            },
            {
                title: 'MW Latency',
                sortRuleId: 'mirroredWriteLatency',
                sizeWeight: 2,
                type: 'value',
                getValue: (array: PureArray) => this.formatVU(array.performanceData.mirroredWriteLatencyStr),
            },
            {
                title: 'O Latency',
                sortRuleId: 'otherLatency',
                sizeWeight: 2,
                type: 'value',
                getValue: (array: PureArray) => this.formatVU(array.performanceData.otherLatencyStr),
            },
            {
                title: 'IOPS',
                sortRuleId: 'iops',
                sizeWeight: 2,
                type: 'value',
                getValue: (array: PureArray) => this.formatVU(array.performanceData.iopsStr),
            },
            {
                title: 'Bandwidth',
                sortRuleId: 'bandwidth',
                sizeWeight: 2,
                type: 'value',
                getValue: (array: PureArray) => this.formatVU(array.performanceData.bandwidthStr),
            },
            {
                title: 'SafeMode',
                sortRuleId: 'safeModeStatus',
                sizeWeight: 2,
                type: 'value',
                getValue: (array: PureArray) => array.safeModeStatus,
            },
            {
                title: 'EOL Status',
                sortRuleId: 'eol',
                sizeWeight: 2,
                type: 'eol',
                getValue: (array: PureArray) => '',
                showColumn: (arrays: UnifiedArray[]) =>
                    this.hwEOLEnabled && arrays.some(array => array.has_end_of_life_hardware),
            },
        ];
    }

    private getFaFbCapacityDownEnabledConfig(): IPureArrayListColumn[] {
        return [
            {
                title: '',
                sortRuleId: 'alertSeverity',
                sizeWeight: 0,
                type: 'alert',
                getValue: (array: PureArray) => '',
            },
            {
                title: 'Name',
                sortRuleId: 'hostname',
                sizeWeight: 3,
                type: 'array-name',
                getValue: (array: PureArray) => array.hostname,
            },
            {
                title: 'Model',
                sortRuleId: 'model',
                sizeWeight: 2,
                type: 'value',
                getValue: (array: PureArray) => array.model,
            },
            {
                title: 'Version',
                sortRuleId: 'version',
                sizeWeight: 2,
                type: 'value',
                getValue: (array: PureArray) => array.version,
            },
            {
                title: 'Protocol',
                sortRuleId: 'protocol',
                sizeWeight: 2,
                type: 'value',
                getValue: (array: PureArray) => array.protocolString,
            },
            {
                title: 'Instant Capacity',
                sortRuleId: 'instantCapacity',
                sizeWeight: 2,
                type: 'value',
                getValue: (array: PureArray) => this.formatVU(array.capacityData.instantCapacityStr),
                showColumn: arrays => this.capacityDownLicensingEnabled,
            },
            {
                title: '% Full',
                sortRuleId: 'percFull',
                sizeWeight: 2,
                type: 'capacity',
                getValue: (array: PureArray) => array.capacityData.actualPercFull,
            },
            {
                title: 'Capacity',
                sortRuleId: 'capacity',
                sizeWeight: 2,
                type: 'value',
                getValue: (array: PureArray) => this.formatVU(array.capacityData.capacityStr),
            },
            {
                title: 'Provisioned',
                sortRuleId: 'provisioned',
                sizeWeight: 2,
                type: 'value',
                getValue: (array: PureArray) => this.formatVU(array.capacityData.provisionedStr),
            },
            {
                title: 'Reduction',
                sortRuleId: 'dataReduction',
                sizeWeight: 2,
                type: 'value',
                getValue: (array: PureArray) => array.capacityData.dataReductionStr,
            },
            {
                title: 'R Latency',
                sortRuleId: 'readLatency',
                sizeWeight: 2,
                type: 'value',
                getValue: (array: PureArray) => this.formatVU(array.performanceData.readLatencyStr),
            },
            {
                title: 'W Latency',
                sortRuleId: 'writeLatency',
                sizeWeight: 2,
                type: 'value',
                getValue: (array: PureArray) => this.formatVU(array.performanceData.writeLatencyStr),
            },
            {
                title: 'MW Latency',
                sortRuleId: 'mirroredWriteLatency',
                sizeWeight: 2,
                type: 'value',
                getValue: (array: PureArray) => this.formatVU(array.performanceData.mirroredWriteLatencyStr),
            },
            {
                title: 'O Latency',
                sortRuleId: 'otherLatency',
                sizeWeight: 2,
                type: 'value',
                getValue: (array: PureArray) => this.formatVU(array.performanceData.otherLatencyStr),
            },
            {
                title: 'IOPS',
                sortRuleId: 'iops',
                sizeWeight: 2,
                type: 'value',
                getValue: (array: PureArray) => this.formatVU(array.performanceData.iopsStr),
            },
            {
                title: 'Bandwidth',
                sortRuleId: 'bandwidth',
                sizeWeight: 2,
                type: 'value',
                getValue: (array: PureArray) => this.formatVU(array.performanceData.bandwidthStr),
            },
            {
                title: 'SafeMode',
                sortRuleId: 'safeModeStatus',
                sizeWeight: 2,
                type: 'value',
                getValue: (array: PureArray) => array.safeModeStatus,
            },
            {
                title: 'EOL Status',
                sortRuleId: 'eol',
                sizeWeight: 2,
                type: 'eol',
                getValue: (array: PureArray) => '',
                showColumn: (arrays: UnifiedArray[]) =>
                    this.hwEOLEnabled && arrays.some(array => array.has_end_of_life_hardware),
            },
        ];
    }

    private formatVU(valueUnit: IValueUnit): string {
        return valueUnit ? valueUnit.value + ' ' + valueUnit.unit : '-';
    }
}
