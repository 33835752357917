import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Params } from '@angular/router';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import {
    ArrayContractStatus,
    FeatureFlagDxpService,
    ResourceStatus,
    SafeModeStatusEnum,
    ServiceCatalogQuote,
} from '@pure1/data';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { FeatureNames, PureArray } from '../../model/model';
import { SafeModeSchedulerModalService } from '../../safemode/services/safemode-scheduler-modal.service';
import { getSafeModeApplianceFilter, getSafeModeLink } from '../../safemode/util';
import { ArraysManager } from '../../services/arrays-manager.service';
import { formatSize } from '../../utils/formatSize';
import PureUtils from '../../utils/pure_utils';

/**
 * Array card for FlashArray and FlashBlade
 */
@Component({
    selector: 'array-card-fa-fb',
    templateUrl: 'array-card-fa-fb.component.html',
})
export class ArrayCardFaFbComponent implements OnInit, OnDestroy {
    @Input() readonly array: PureArray;
    @Input() readonly alerts: IAlert[];
    @Input() readonly isCardFlipped: boolean;
    @Input() readonly showWarningCardForOutOfSupportAppliance: boolean = true; // defaults to true
    @Input() readonly allOrders: ServiceCatalogQuote[];
    @Output() readonly onFlipCard = new EventEmitter<{ event: UIEvent }>();
    @ViewChild('safeModeIconPopover') safeModeIconPopover: NgbPopover;

    readonly ArrayContractStatusExpired = ArrayContractStatus.EXPIRED;
    readonly ResourceStatus = ResourceStatus;

    hoverState = false;
    safeModeLink: string;
    selectedArrayExistingQuote: ServiceCatalogQuote;
    isCardShowArrayInfo: boolean;
    safeModeMultipartyFeatureFlag = false;
    capacityDownLicensingEnabled = false;
    eocEnabled = false;
    hasCardEverFlipped = false;

    private destroy$ = new Subject<void>();

    safeModeStatusEnum = SafeModeStatusEnum;
    hwEOLEnabled = false;

    constructor(
        public arraysManager: ArraysManager,
        private featureFlagDxpService: FeatureFlagDxpService,
        private safeModeSchedulerModal: SafeModeSchedulerModalService,
    ) {}

    ngOnInit(): void {
        this.safeModeLink = getSafeModeLink(getSafeModeApplianceFilter(this.array.name));
        this.selectedArrayExistingQuote = this.arraysManager.getSupportContractRenewalQuote(this.array, this.allOrders);

        this.featureFlagDxpService
            .getFeatureFlag(FeatureNames.SAFE_MODE_MULTIPARTY_AUTH)
            .pipe(take(1))
            .subscribe(feature => {
                this.safeModeMultipartyFeatureFlag = feature?.enabled === true;
            });
        this.featureFlagDxpService
            .getFeatureFlag(FeatureNames.HW_EOL)
            .pipe(take(1))
            .subscribe(feature => {
                this.hwEOLEnabled = feature?.enabled === true;
            });
        this.featureFlagDxpService
            .getFeatureFlag(FeatureNames.CAPACITY_DOWN_LICENSING_APPLIANCE)
            .pipe(take(1))
            .subscribe(feature => {
                this.capacityDownLicensingEnabled = feature?.enabled === true;
            });
        this.featureFlagDxpService
            .getFeatureFlag(FeatureNames.EOC)
            .pipe(take(1))
            .subscribe(feature => {
                this.eocEnabled = feature?.enabled;
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.array) {
            this.safeModeLink = getSafeModeLink(getSafeModeApplianceFilter(this.array.name));
        }

        if (changes.array || changes.allOrders) {
            this.selectedArrayExistingQuote = this.arraysManager.getSupportContractRenewalQuote(
                this.array,
                this.allOrders,
            );
            this.isCardShowArrayInfo = this.arraysManager.isCardShowArrayInfo(this.array);
        }

        if (changes.isCardFlipped) {
            // Effectively lazy-load the card back only once it is actually visible
            this.hasCardEverFlipped ||= this.isCardFlipped;
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    flipCard(event: UIEvent): void {
        this.onFlipCard.emit({ event: event });
    }

    getCapacityDialCssClass(): string {
        const pctFull = Number(this.array.capacityData.normalizedPercFull);
        return PureUtils.getCapacityDialCssClass(pctFull);
    }

    getCapacityString(): string {
        const unit = this.array.capacityData.capacityStr.unit;
        const used = formatSize(this.array.capacityData.total, 1, false, unit);
        const capacity = formatSize(this.array.capacityData.capacity, 1, false, unit);
        return `${used.value} / ${capacity.value} ${unit}`;
    }

    getSafeModeState(): string {
        return '/dashboard/assessment/data-protection-v2';
    }

    getSafeModeUIParams(array: PureArray): Params {
        return { filter: JSON.stringify(getSafeModeApplianceFilter(array.name)) };
    }

    openSafeModeMultipartAuthModal(array: PureArray): void {
        this.safeModeSchedulerModal.openSupportScheduler(array.arrayId);
    }

    hasEolComponents(): boolean {
        return this.hwEOLEnabled && this.array.has_end_of_life_hardware;
    }
}
