<div class="container-fluid array-info">
    <div class="row">
        <div class="col-xs-6">{{ selectedArray?.model }}</div>
        <div class="col-xs-6">{{ selectedArray?.os }} {{ selectedArray?.version }}</div>
    </div>
</div>
<div class="out-of-contract-section">
    <div class="expiration-text">
        Evergreen Subscription expired on
        <span class="expiration-date">{{ $any(selectedArray?.contract_expiration) | date: 'yyyy-MM-dd' }}</span>
    </div>
    <pureui-svg svg="support_icon.svg" [height]="35"></pureui-svg>
    <div class="out-of-contract-info">
        <span class="highlighted-text">{{ theDaysLeftUntil90Days }}</span> days<br />remaining before removal from Pure1
        Manage.
        <span class="contact-text">Support is no longer available. To renew, click below. </span>
    </div>
    <hive-button
        emphasis="primary"
        label="Renew"
        class="contact-btn"
        [disabled]="('PURE1:write:subscriptions_renew' | isNotAllowed) || isSubmitted || !isValidToRequest"
        hiveTooltip
        [hiveTooltipCustomContentTemplateRef]="
            (isSubmitted || !isValidToRequest) && !('PURE1:write:subscriptions_renew' | isNotAllowed)
                ? requestedTooltip
                : null
        "
        (click)="modalService.open(contactRenewals)"
    ></hive-button>
</div>

<ng-template #contactRenewals let-modal>
    <contact-renewals-modal
        [selectedArray]="selectedArray"
        [activeModal]="modal"
        (submitChangesComplete)="submitChangesComplete()"
    >
    </contact-renewals-modal>
</ng-template>
<ng-template #requestedTooltip>
    A renewal was requested on {{ selectedArray.contract_last_renew_requested | moment2date | date: 'yyyy-MM-dd' }}. For
    updates on your request, please contact renewals&#64;purestorage.com.
</ng-template>
