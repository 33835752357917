<div class="ur-container">
    <div class="drawer-title">
        <div class="drawer-title-back-button hover-effect" (click)="urStateService.previousDrawerState()">
            <pureui-svg class="pstg-action-icon back-icon" [svg]="'arrow-left.svg'" [height]="15"></pureui-svg>
        </div>

        <input
            type="text"
            [formControl]="viewName"
            placeholder="View name"
            class="view-name-input"
            [ngClass]="{ 'name-invalid': !getPrimaryActionBtnEnableStatus() }"
            ngbTooltip
            triggers="manual"
            placement="top"
            container="body"
            [autoClose]="false"
            #viewNameErrorTooltip="ngbTooltip"
            (focus)="updateErrorTooltipText(viewName.value)"
            maxlength="63"
        />
    </div>

    <div class="ur-body" [pureshadowscroll]="{ scrollElem: '.appliances-list', shadowElem: '.ur-body-header' }">
        <div class="fixed-height ur-body-header">
            <pureui-spinner class="p-loading" [pureuiShow]="loading"></pureui-spinner>
            <gfb
                [barId]="barId"
                [entities]="['arrays', 'subscription license']"
                defaultEntity="arrays"
                defaultKey="array_name"
                filterMode="views"
                [filterToOrgId]="currentUser?.organization_id"
            ></gfb>
            <div class="indicator" *ngIf="state === 'assign' || state === 'create'">
                <span class="label">Selected {{ getAssigneeText() }}:</span>
                <span class="count">{{ selectedAssignees.length }}</span>
            </div>
            <div class="indicator">
                <span class="label">FlashArrays:</span>
                <span class="count">{{ flashArrayCount }}</span>
            </div>
            <div class="indicator">
                <span class="label">FlashBlades:</span>
                <span class="count">{{ flashBladeCount }}</span>
            </div>
        </div>
        <div class="appliances-list">
            <div class="appliance" *ngFor="let appliance of appliances; trackBy: 'id' | trackByProperty">
                <div class="appliance-product">{{ appliance.product | productShortName }}</div>
                <div class="appliance-name">
                    <span [ngbTooltip]="appliance.name" placement="top" container="body">{{ appliance.name }}</span>
                </div>
                <div class="appliance-model">{{ appliance.model }}</div>
            </div>
        </div>
    </div>
    <div class="ur-footer">
        <button
            class="btn btn-primary float-xs-right"
            [disabled]="('PURE1:write:view' | isNotAllowed) || !getPrimaryActionBtnEnableStatus()"
            (click)="applyChanges()"
            angulartics2On="click"
            [angularticsAction]="'UM - Edit view - confirm view ' + state"
            angularticsCategory="Action"
        >
            {{ getActionButtonText() }}
        </button>
        <button
            class="btn float-xs-right btn-info"
            (click)="urStateService.previousDrawerState()"
            angulartics2On="click"
            [angularticsAction]="'UM - Edit view - cancel view ' + state"
            angularticsCategory="Action"
        >
            Cancel
        </button>
    </div>
</div>
