import { Component, Input, OnInit } from '@angular/core';
import { UnifiedArray } from '@pure1/data';
import moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { clone, cloneDeep } from 'lodash';
import { Moment } from 'moment/moment';

@Component({
    selector: 'appliance-out-of-contract-template',
    templateUrl: 'appliance-out-of-contract-template.component.html',
})
export class ApplianceOutOfContractTemplateComponent implements OnInit {
    @Input() readonly selectedArray: UnifiedArray;

    endOfContractDate: moment.Moment;
    isSubmitted = false;
    isValidToRequest = false;
    theDaysLeftUntil90Days: number;

    readonly TODAY = moment.utc();

    constructor(public modalService: NgbModal) {}

    ngOnInit(): void {
        this.endOfContractDate = this.selectedArray?.contract_expiration?.clone()?.add(90, 'days');
        this.isValidToRequest =
            !this.selectedArray.contract_last_renew_requested ||
            moment(this.selectedArray.contract_last_renew_requested).isBefore(moment.utc().subtract(7, 'days'));
        this.theDaysLeftUntil90Days = this.endOfContractDate.diff(this.TODAY, 'days');
    }

    submitChangesComplete(): void {
        this.isSubmitted = true;
    }
}
