<hive-content-dialog
    #uploadDialog
    [heading]="modalTitle"
    [description]="modalDescription"
    [showCloseButton]="true"
    [closeOnBackdropClick]="true"
    size="small"
>
    <div *ngIf="isLoading" class="loading-spinner">
        <pureui-spinner class="p-loading" [pureuiShow]="isLoading"></pureui-spinner>
    </div>
    <quote-poupload-dialog
        [opportunityQuote]="opportunityQuote"
        [fileToUpload]="fileToUpload"
        [errorMessage]="disabledMessage"
        (fileChange)="onFileChange($event)"
    />

    <hive-button
        *ngIf="opportunityQuote?.status !== QuoteStatus.PO_UPLOADED"
        slot="actions-right"
        emphasis="secondary"
        label="Cancel"
        (click)="closeUpload()"
    />

    <hive-button
        *ngIf="opportunityQuote?.status !== QuoteStatus.PO_UPLOADED"
        slot="actions-right"
        emphasis="primary"
        label="Upload"
        (click)="submitAttachment()"
        [disabled]="!!disabledMessage"
        [hiveTooltip]="disabledMessage"
    />
</hive-content-dialog>

<hive-content-dialog
    #successDialog
    heading="Your Purchase Order is submitted"
    description="Our team will take action shortly."
    [showCloseButton]="true"
    [closeOnBackdropClick]="true"
    size="small"
>
    <hive-button slot="actions-right" emphasis="secondary" label="Close" (click)="successDialog.hide()"></hive-button>
</hive-content-dialog>

<hive-content-dialog
    #errorDialog
    heading="Oops! Something went wrong."
    [description]="errorMessage"
    [showCloseButton]="true"
    [closeOnBackdropClick]="true"
    size="small"
>
    <div class="quote-error-dialog-body">
        <svg class="pstg-action-icon" hive-svg="warning-icon.svg" [height]="20"></svg>
        Please try again. If the issue persists, contact Pure1 Technical Services.
    </div>
    <hive-button slot="actions-right" emphasis="secondary" label="Close" (click)="errorDialog.hide()"></hive-button>
</hive-content-dialog>
