import { combineLatest, Observable, of, Subject, switchMap } from 'rxjs';
import { catchError, map, startWith, takeUntil, throttleTime } from 'rxjs/operators';
import { EnvironmentInjector, Injectable, OnDestroy, runInInjectionContext } from '@angular/core';
import { FeatureNames } from '../../model/model';
import { AuthorizationService } from '@pure/authz-authorizer';
import { FeatureFlagService, FeatureFlagStatusResponse } from '@pure/pure1-ui-platform-angular';
import { ConditionExpr, PathExpr, SubTabConfig, TabConfig } from './tabs.model';

export const PERMISSIONS_THROTTLE_MS = 1;
export const TAB_NOT_SHOWN: unique symbol = Symbol();

const isTabShown = <Tab>(tab: Tab | typeof TAB_NOT_SHOWN): tab is Tab => tab !== TAB_NOT_SHOWN;

interface ITabPath {
    path?: string;
    href?: string;
    target?: string;
}

export interface ITab extends ITabPath {
    active?: boolean;
    title: string;
}

export interface IMainTab extends ITabPath {
    active?: boolean;
    title: string;
    icon?: string;
    subTabs?: ITab[];
}

export interface Bag {
    isAllowedMap: Map<string, boolean>;
    isFeatureEnabledMap: FeatureFlagStatusResponse;
}

@Injectable({ providedIn: 'root' })
export class TabsService implements OnDestroy {
    private destroy$ = new Subject<void>();

    constructor(
        private featurePolicyService: FeatureFlagService,
        private authorizationService: AuthorizationService,
        private environmentInjector: EnvironmentInjector,
    ) {}

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    build$(menu: TabConfig[]): Observable<readonly IMainTab[]> {
        if (menu.length === 0) {
            return of([]);
        }

        /**
         * Collect declared permissions and feature names from the menu configuration.
         */
        const permissionSet = new Set<string>();
        const featureNameSet = new Set<FeatureNames>();

        function walkCondition(node?: ConditionExpr) {
            if (!node || typeof node === 'function') {
                return;
            } else if (node.check === 'isAllowed') {
                permissionSet.add(node.permission);
            } else if (node.check === 'featureEnabled') {
                featureNameSet.add(node.featureName);
            } else if (node.check === 'every' || node.check === 'some') {
                for (const c of node.conditions) {
                    walkCondition(c);
                }
            }
        }

        for (const tabConfig of menu) {
            walkCondition(tabConfig.condition);

            for (const subTabConfig of tabConfig.items ?? []) {
                walkCondition(subTabConfig.condition);
            }
        }

        /**
         * Call isAllowed for each found permission. Combine the values into a map on every emit.
         * Many values resolve at the same time, so we use throttleTime(1).
         */
        let isAllowedMap$: Observable<Map<string, boolean>>;

        if (permissionSet.size === 0) {
            isAllowedMap$ = of(new Map());
        } else {
            isAllowedMap$ = combineLatest(
                [...permissionSet].map(p =>
                    this.authorizationService.isAllowed(p).pipe(map(isAllowed => [p, isAllowed] as const)),
                ),
            ).pipe(
                throttleTime(PERMISSIONS_THROTTLE_MS),
                takeUntil(this.destroy$),
                map(entries => new Map(entries)),
            );
        }

        /**
         * Request feature policy statuses at the same time as permissions.
         * While loading, assume no features are enabled.
         */
        const isFeatureEnabledMap$ = this.featurePolicyService
            .getFeatureFlags([...featureNameSet])
            .pipe(takeUntil(this.destroy$), startWith({}));

        /**
         * Construct a bag of permissions and feature statuses to be used in condition evaluation.
         */
        const bag$: Observable<Bag> = combineLatest({
            isAllowedMap: isAllowedMap$,
            isFeatureEnabledMap: isFeatureEnabledMap$,
        });

        return bag$.pipe(
            switchMap(bag => combineLatest(menu.map(tab => this.makeMainTab$(bag, tab)))),
            map(mainTabs => mainTabs.filter(isTabShown)),
            takeUntil(this.destroy$),
        );
    }

    makeMainTab$(bag: Bag, tab: TabConfig): Observable<IMainTab | typeof TAB_NOT_SHOWN> {
        const children = tab.items ?? [];
        let subTabs$: Observable<ITab[]>;
        if (children.length > 0) {
            subTabs$ = combineLatest(tab.items.map(item => this.makeSubTab$(bag, item))).pipe(
                map(subTabs => subTabs.filter(isTabShown)),
            );
        } else {
            subTabs$ = of([]);
        }

        return combineLatest({
            meetsCondition: this.evaluateCondition$(bag, tab.condition),
            pathProperties: this.evaluatePath$(tab.path),
            subTabs: subTabs$,
        }).pipe(
            map(({ meetsCondition, pathProperties, subTabs }) => {
                if (
                    !meetsCondition ||
                    pathProperties === TAB_NOT_SHOWN ||
                    (subTabs.length === 0 && children.length > 0 && !tab.options?.showIfEmpty)
                ) {
                    return TAB_NOT_SHOWN;
                }

                const mainTab: IMainTab = {
                    title: tab.title,
                    active: false,
                    icon: tab.icon,
                    ...pathProperties,
                };

                mainTab.subTabs = subTabs;

                return mainTab;
            }),
        );
    }

    makeSubTab$(bag: Bag, item: SubTabConfig): Observable<ITab | typeof TAB_NOT_SHOWN> {
        return combineLatest({
            meetsCondition: this.evaluateCondition$(bag, item.condition),
            pathProperties: this.evaluatePath$(item.path),
        }).pipe(
            map(({ meetsCondition, pathProperties }) => {
                if (!meetsCondition || pathProperties === TAB_NOT_SHOWN) {
                    return TAB_NOT_SHOWN;
                }

                const tab: ITab = {
                    title: item.title,
                    active: false,
                    ...pathProperties,
                };

                return tab;
            }),
        );
    }

    evaluateCondition$(bag: Bag, expr?: ConditionExpr): Observable<boolean> {
        if (!expr) {
            return of(true); // default
        } else if (typeof expr === 'function') {
            const result$ = runInInjectionContext(this.environmentInjector, expr);
            return result$.pipe(
                catchError(e => {
                    console.error('Failed to evaluate tab condition:', e);
                    return of(false);
                }),
                startWith(false),
                takeUntil(this.destroy$),
            );
        } else if (expr.check === 'isAllowed') {
            return of(Boolean(bag.isAllowedMap.get(expr['permission'])));
        } else if (expr.check === 'featureEnabled') {
            return of(Boolean(bag.isFeatureEnabledMap[expr['featureName']]?.enabled));
        } else if (expr.check === 'every') {
            const subConditions = expr['conditions'].map((c: ConditionExpr) => this.evaluateCondition$(bag, c));
            if (subConditions.length === 0) {
                return of(true);
            } else {
                return combineLatest(subConditions).pipe(map((results: boolean[]) => results.every(Boolean)));
            }
        } else if (expr.check === 'some') {
            const subConditions = expr['conditions'].map((c: ConditionExpr) => this.evaluateCondition$(bag, c));
            if (subConditions.length === 0) {
                return of(true);
            } else {
                return combineLatest(subConditions).pipe(map((results: boolean[]) => results.some(Boolean)));
            }
        } else {
            console.error('Unknown condition type:', typeof expr, expr['check']);
            return of(false);
        }
    }

    evaluatePath$(path?: PathExpr): Observable<ITabPath | typeof TAB_NOT_SHOWN> {
        if (!path) {
            return of({});
        } else if (typeof path === 'string') {
            return of({ path });
        } else if (path.type === 'url') {
            return of({ href: path.href, target: '_blank' });
        } else {
            console.error('Unknown path type:', path);
            return of(TAB_NOT_SHOWN);
        }
    }
}
