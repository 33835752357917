import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Angulartics2Module } from 'angulartics2';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ArchwizardModule } from '@achimha/angular-archwizard';
import { FbGuiHwModule } from 'fb-gui-hw';

import { AlertsSharedModule } from '../messages/alerts/alerts.shared.module';
import { GfbModule } from '../gfb/gfb.module';
import { SpotlightBannerModule } from '../spotlight-banner/spotlight-banner.module';
import { TagsModule } from '../tags/tags.module';
import { UIModule } from '../ui/ui.module';

import { ApplianceExpiredTemplateComponent } from './appliance-expired-template/appliance-expired-template.component';
import { AppliancesViewComponent } from './appliances-view/appliances-view.component';
import { ArrayCardBackComponent } from './array-card-back/array-card-back.component';
import { ArrayCardFaFbComponent } from './array-card-fa-fb/array-card-fa-fb.component';
import { ArrayCardHeaderComponent } from './array-card-header/array-card-header.component';
import { ArrayExpandedCardComponent } from './array-expanded-card/array-expanded-card.component';
import { ArrayExpandedCardCapacityComponent } from './array-expanded-card/capacity/array-expanded-card-capacity.component';
import { ArrayExpandedCardCapacityChartComponent } from './array-expanded-card/capacity/chart/array-expanded-card-capacity-chart.component';
import { ArrayExpandedCardHealthComponent } from './array-expanded-card/health/array-expanded-card-health.component';
import { ArrayExpandedCardHealthFArrayComponent } from './array-expanded-card/health/farray/array-expanded-card-health-farray.component';
import { ArrayExpandedCardHealthFBladeComponent } from './array-expanded-card/health/fblade/array-expanded-card-health-fblade.component';
import { ArrayExpandedCardPerformanceComponent } from './array-expanded-card/performance/array-expanded-card-performance.component';
import { ArrayExpandedCardPerformanceChartComponent } from './array-expanded-card/performance/chart/array-expanded-card-performance-chart.component';
import { ArraysCardViewComponent } from './arrays-card-view/arrays-card-view.component';
import { ArraysExpandedCardViewComponent } from './arrays-expanded-card-view/arrays-expanded-card-view.component';
import { ArraysListTableComponent } from './arrays-list-table/arrays-list-table.component';
import { ArraysListViewComponent } from './arrays-list-view/arrays-list-view.component';
import { ArraysMapViewComponent } from './arrays-map-view/arrays-map-view/arrays-map-view.component';
import { ContactsArrayListComponent } from './arrays-map-view/contacts-array-list/contacts-array-list.component';
import { ContactsDrawerComponent } from './arrays-map-view/contacts-drawer/contacts-drawer.component';
import { EolOverlayComponent } from './eol-overlay/eol-overlay.component';
import { MapModalComponent } from './arrays-map-view/map-modal/map-modal.component';
import { MapModalService } from './arrays-map-view/map-modal/map-modal.service';
import { UpdateAddressComponent } from './arrays-map-view/update-address/update-address.component';
import { OpportunityService } from '../services/opportunity.service';
import {
    ButtonComponent,
    CheckboxComponent,
    LinkComponent,
    MenuModule,
    SvgDirective,
    TextAreaComponent,
    TooltipModule,
} from '@pure/hive';
import { AuthorizerModule } from '@pure/authz-authorizer';
import { RouterModule } from '@angular/router';
import { IntroducingIdentityCenterComponent } from './introducing-identity-center/introducing-identity-center.component';
import { IntroducingIdentityCenterPromotionComponent } from './introducing-identity-center-promotion/introducing-identity-center-promotion.component';
import { Pure1AppLinkPipe } from '@pure/pure1-ui-platform-angular';
import { PrefillGoogleFormDirective } from '../ui/directives/prefill-google-form.directive';
import { ApplianceOutOfContractTemplateComponent } from './appliance-out-of-contract-template/appliance-out-of-contract-template.component';
import { ContactRenewalsModalComponent } from './appliance-out-of-contract-template/contact-renewals-modal/contact-renewals-modal.component';

@NgModule({
    imports: [
        AuthorizerModule,
        AlertsSharedModule,
        Angulartics2Module,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        GfbModule,
        InfiniteScrollModule,
        NgbModule,
        SpotlightBannerModule,
        TagsModule,
        UIModule,
        ArchwizardModule,
        FbGuiHwModule,
        MenuModule,
        RouterModule,
        TooltipModule,
        SvgDirective,
        CheckboxComponent,
        LinkComponent,
        ButtonComponent,
        Pure1AppLinkPipe,
        PrefillGoogleFormDirective,
        TextAreaComponent,
    ],
    declarations: [
        ApplianceExpiredTemplateComponent,
        ApplianceOutOfContractTemplateComponent,
        AppliancesViewComponent,
        ArrayCardBackComponent,
        ArrayCardFaFbComponent,
        ArrayCardHeaderComponent,
        ArrayExpandedCardComponent,
        ArrayExpandedCardCapacityComponent,
        ArrayExpandedCardCapacityChartComponent,
        ArrayExpandedCardHealthComponent,
        ArrayExpandedCardHealthFArrayComponent,
        ArrayExpandedCardHealthFBladeComponent,
        ArrayExpandedCardPerformanceComponent,
        ArrayExpandedCardPerformanceChartComponent,
        ArraysCardViewComponent,
        ArraysExpandedCardViewComponent,
        ArraysListTableComponent,
        ArraysListViewComponent,
        ArraysMapViewComponent,
        ContactsArrayListComponent,
        ContactsDrawerComponent,
        ContactRenewalsModalComponent,
        EolOverlayComponent,
        MapModalComponent,
        UpdateAddressComponent,
        IntroducingIdentityCenterComponent,
        IntroducingIdentityCenterPromotionComponent,
    ],
    providers: [MapModalService, OpportunityService],
})
export class ArraysModule {}
