import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {
    CheckboxComponent,
    DropdownModule,
    LabelComponent,
    LinkComponent,
    PaginationComponent,
    TableModule,
} from '@pure/hive';
import { ArchwizardModule } from '@achimha/angular-archwizard';
import { Angulartics2Module } from 'angulartics2';
import { SupportCasesRedirectDirective } from '../support/support-cases-redirect.directive';
import { UIModule } from '../ui/ui.module';

// Shared for SafeMode and SafeMode with multiparty authentication schedulers
import { SafeModeEnablePlannerComponent } from './safemode-scheduler-common/safemode-enable-planner/safemode-enable-planner.component';
import { TimeSlotPickerComponent } from './safemode-scheduler-common/timeslot-picker/timeslot-picker.component';

// SafeMode with multiparty authentication scheduler components
import { SafeModeArraySelectorMultipartyAuthComponent } from './safemode-scheduler-multiparty-auth/safemode-array-selector/safemode-array-selector-multiparty-auth.component';
import { SafeModeEnableScheduledConfirmationMultipartyAuthComponent } from './safemode-scheduler-multiparty-auth/safemode-enable-scheduled-confirmation/safemode-enable-scheduled-confirmation-multiparty-auth.component';
import { SafemodeIntroComponent } from './safemode-scheduler-multiparty-auth/safemode-intro/safemode-intro.component';
import { SafeModePGroupsSelectorMultipartyAuthComponent } from './safemode-scheduler-multiparty-auth/safemode-pgroups-selector/safemode-pgroups-selector-multiparty-auth.component';
import { SafeModeSchedulerMultipartyAuthComponent } from './safemode-scheduler-multiparty-auth/safemode-scheduler-multiparty-auth/safemode-scheduler-multiparty-auth.component';

// scheduler components
import { SafeModeArraySelectorComponent } from './safemode-scheduler/safemode-array-selector/safemode-array-selector.component';
import { SafeModeEnableConfirmationComponent } from './safemode-scheduler/safemode-enable-confirmation/safemode-enable-confirmation.component';
import { SafeModeEnableScheduledConfirmationComponent } from './safemode-scheduler/safemode-enable-scheduled-confirmation/safemode-enable-scheduled-confirmation.component';
import { SafeModeSchedulerComponent } from './safemode-scheduler/safemode-scheduler/safemode-scheduler.component';

import { GetPgroupNamePipe } from './pipes/get-pgroup-name.pipe';
import { SafeModeSchedulerModalService } from './services/safemode-scheduler-modal.service';
import { SafemodeChangeRequestDetailsComponent } from './safemode-shared/safemode-change-request-details/safemode-change-request-details.component';
import { SafemodePgroupComponent } from './safemode-shared/safemode-pgroup/safemode-pgroup.component';
import { SafemodePlannerComponent } from './safemode-scheduler-multiparty-auth/safemode-planner/safemode-planner.component';
import { GetPrettyTimePipe } from './pipes/get-pretty-time.pipe';
import { RouterModule } from '@angular/router';
import { AuthorizerModule } from '@pure/authz-authorizer';

@NgModule({
    imports: [
        Angulartics2Module,
        ArchwizardModule,
        AuthorizerModule,
        CommonModule,
        UIModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        DropdownModule,
        CheckboxComponent,
        RouterModule,
        PaginationComponent,
        TableModule,
        LabelComponent,
        SupportCasesRedirectDirective,
        LinkComponent,
    ],
    declarations: [
        TimeSlotPickerComponent,
        SafeModeArraySelectorComponent,
        SafeModeEnableConfirmationComponent,
        SafeModeEnablePlannerComponent,
        SafeModeEnableScheduledConfirmationComponent,
        SafeModeSchedulerComponent,
        SafemodeIntroComponent,
        SafeModeArraySelectorMultipartyAuthComponent,
        SafeModePGroupsSelectorMultipartyAuthComponent,
        SafeModeSchedulerMultipartyAuthComponent,
        SafeModeEnableScheduledConfirmationMultipartyAuthComponent,
        GetPgroupNamePipe,
        SafemodeChangeRequestDetailsComponent,
        SafemodePgroupComponent,
        SafemodePlannerComponent,
        GetPrettyTimePipe,
    ],
    exports: [SafemodeChangeRequestDetailsComponent],
    providers: [SafeModeSchedulerModalService, GetPgroupNamePipe],
})
export class SafeModeModule {}
