<pureui-spinner class="p-loading" [pureuiShow]="supportLoader.isLoading$ | async"></pureui-spinner>

<div
    *ngIf="!supportCenterEnabled"
    class="row secondary-nav-and-content-wrapper secondary-nav-collapsible"
    [ngClass]="{ 'secondary-nav-collapsed': secondaryNavCollapsed }"
>
    <support-nav
        id="support-sidebar"
        class="secondary-left-nav"
        *ngIf="!caOnly"
        [cases]="caseManager.forNav()"
        [closedCases]="caseManager.closedCases()"
        [selectedCaseId]="caseId"
        (newCaseCreated)="newCaseCreated($event)"
        (toggleSecondaryNavCollapsed)="toggleSecondaryNavCollapsed()"
    ></support-nav>

    <div class="col-xs container-fluid" id="support-main-content">
        <banners class="support-banners"></banners>
        <div id="support-main" class="row" [ngClass]="{ 'single-case': caseId }">
            <router-outlet></router-outlet>
            <div id="main-feed" *ngIf="!caseId">
                <div *ngIf="showNoCaseSelectedMessage()" class="no-case-selected-message">
                    To view specific case details, please select a case from the sidebar on the left.
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="supportCenterEnabled" class="support-center-block">
    <pureui-svg svg="cases-support-center.svg" height="188"></pureui-svg>
    <span class="support-center-title"> Cases Moved to a New Home! </span>
    <div class="content-wrap">
        <div>
            We've moved our support cases dashboard to the new
            <a hive-link (click)="openSupportCenter()">Pure Support Center</a>, a new platform designed to enhance your
            support experience with Pure Storage. This move aligns with our commitment to providing streamlined,
            efficient, and user-centric support.
        </div>
        <ul>
            <li
                ><span class="text-bolder">Unified Dashboard:</span> Manage all your support cases from all products in
                one place, improving visibility and efficiency.
            </li>
            <li
                ><span class="text-bolder">Advanced Search & Knowledge Base:</span> Quickly find solutions with our
                AI-powered search and comprehensive knowledge base.
            </li>
            <li
                ><span class="text-bolder">Enhanced Security:</span> Benefit from role-based access control for better
                security</li
            >
        </ul>
        <div class="text-bolder">Get Started Now</div>
        <div>
            Dive into the Pure Support Center and explore these new features designed to elevate your support
            experience. Your feedback is invaluable as we continue to refine and enhance the platform. We're excited to
            embark on this new journey with you!
        </div>
    </div>
    <div class="support-center-buttons">
        <hive-button
            emphasis="primary"
            label="Open Support Case"
            icon="regular/arrow-up-right-from-square.svg"
            (click)="openCreateCasePage()"
        ></hive-button>
        <hive-button
            emphasis="secondary"
            label="Browse Cases"
            icon="regular/arrow-up-right-from-square.svg"
            (click)="openSupportCenter()"
        ></hive-button>
    </div>
</div>
