import { PreloadAllModules } from '@angular/router';

export const environment = {
    production: true,

    // In prod, users can be on the page while a deployment goes out. To avoid trying to load outdated files and
    // getting a chunk error, preload everything.
    preloadStrategy: PreloadAllModules,
    buildVersion: '1.0.3168',
};
