import { Injectable } from '@angular/core';

import { map, Observable, Subject, tap } from 'rxjs';
import { DraasApiUpgradeExecution, DraasApiScheduleUpgrade } from '@pure/paas-api-gateway-client-ts';
import { DraasApiConfig } from './disaster-recovery-constants';
import {
    DisasterRecoveryProtectionGroup,
    DisasterRecoveryUpgradeExecution,
    DisasterRecoveryUpgradeExecutionStep,
} from '@pure1/data';
import { DisasterRecoveryThrottlingHttpClient } from './disaster-recovery-throttling-http-client.service';

@Injectable({ providedIn: 'root' })
export class DisasterRecoveryUpgradesService {
    readonly upgradeChanged$: Subject<string> = new Subject();

    constructor(protected http: DisasterRecoveryThrottlingHttpClient) {}

    protected getEndpoint(clusterId: string): string {
        return `${DraasApiConfig.getUrlPrefix()}/api/2.0/clusters/${clusterId}/upgrades`;
    }

    getCurrentUpgrade(clusterId: string): Observable<DisasterRecoveryUpgradeExecution | null> {
        const url = this.getEndpoint(clusterId);
        return this.http
            .get<DraasApiUpgradeExecution>(url)
            .pipe(map(response => (response ? new DisasterRecoveryUpgradeExecution(response) : null)));
    }

    scheduleUpgrade(clusterId: string, executionId: string, version: number, datetime: Date): Observable<void> {
        const url = `${this.getEndpoint(clusterId)}/${executionId}`;
        const body: DraasApiScheduleUpgrade = {
            scheduled_at_utc: datetime.toISOString(),
            schedule_type: 'CUSTOM',
            version,
        };
        return this.http.post<void>(url, body).pipe(tap(plan => this.upgradeChanged$.next(clusterId)));
    }

    cancelUpgrade(clusterId: string, executionId: string): Observable<void> {
        const url = `${this.getEndpoint(clusterId)}/${executionId}/cancel`;
        return this.http.post<void>(url, null).pipe(tap(plan => this.upgradeChanged$.next(clusterId)));
    }
}
